import SCENES from "./scenes";

const copy = {};

export const TOKEN = `%%%`;

export function replaceTokenWithText(originalText, replacement) {
	return originalText.replace(TOKEN, replacement);
}

copy[SCENES.START] = {
	subhead: `Think you can beat Google Maps Platform?`,
	head: `Delivery Tycoon`,
	body: `Choose a business, plot a delivery route and see how you stack up. With real-world insights and immersive location experiences, it’s tough to beat Google Maps Platform (but fun to\u00A0try).`,
	sentiment: `Good luck!`,
	button: `Let’s get started!`,
};

copy[SCENES.CHOOSE] = {
	head: `Choose your business.`,
	subhead: `We’ll customize your map with the Maps JavaScript\u00A0API.`,
	button: `Next`,
};

copy[SCENES.TUTORIAL] = {
	head: `Congratulations on launching your new\u00A0business!`,
	subhead: `Time to draw the fastest map to deliver your ${TOKEN} to hungry\u00A0customers.`,
	stars: `Make them smile! Completing more deliveries can mean a higher rating for your\u00A0business.`,
	profit: `Make it fast! The faster you deliver, the more money you'll\u00A0make.`,
	button: `Next`,
};

copy[SCENES.DRAW] = {
	head: `Draw your delivery route.`,
	body: `Using Google Maps Platform, we’ll place your drawn route on real roads with the Roads API, and give you (a little) help by optimizing it with the Directions\u00A0API.`,
	button: `Next`,
};

copy[SCENES.READY] = {
	head: `You've plotted your route.`,
	body: `Now let's see how you do against the Google Maps Platform ${TOKEN}, a smart vehicle imbued with the power of Google Cloud AI and Google\u00A0Maps\u00A0Platform.`,
	button: `Go!`,
};

copy[SCENES.RACE] = {
	alerts: [
		{ copy: `You've hit construction!`, icon: `contruction` },
		{ copy: `You’ve hit traffic!`, icon: `traffic` },
		{ copy: `User reported accident ahead!`, icon: `accident` },
	],
	reroute: `Would you like to reroute?`,
	confirm: {
		button: `Yes`,
		head: `Good Choice!`,
		body: `With the power of Google Maps Platform's live traffic data and Directions API, we've found you a faster delivery\u00A0route.`,
	},
	dismiss: {
		button: `Dismiss`,
		head: `Whoops!`,
		body: `With the power of Google Maps Platform's live traffic data and Directions API, we could have found you a faster delivery route. Next\u00A0time!`,
	},
};

copy[SCENES.FINISH] = {
	win: {
		highScore: {
			head: `You're a human supercomputer!`,
			subhead: `But not everyone has your mapping skills, so empower your employees with Google Cloud, and keep your customers\u00A0smiling.`,
			body: `With Google Maps Platform and Google Cloud AI’s training and prediction services, your employees can plan the best route for up to 25\u00A0waypoints.`,
			button: `Enter your initials`,
		},
		lowScore: {
			head: `You're a master of the map!`,
			subhead: `Not everyone has your mapping skills, so give your employees the gift of Google Cloud, and keep your customers\u00A0smiling.`,
			body: `With Google Maps Platform and Google Cloud AI’s  training and prediction services, your employees can plan the best route for up to 25\u00A0waypoints.`,
			button: `See High Scores`,
		},
	},
	lose: {
		highScore: {
			head: `Amazing!`,
			subhead: `You got a high score, but the Google Maps Platform still won this\u00A0round.`,
			body: `With Google Maps Platform and Google Cloud AI’s training and prediction services, you could have found the best route for up to 25\u00A0waypoints.`,
			button: `Enter your initials`,
		},
		lowScore: {
			head: `Great try!`,
			subhead: `You gave it your best shot, but you didn't win this\u00A0round.`,
			body: `With Google Maps Platform's insight into real-time traffic conditions and Google Cloud AI’s training and prediction services, you could have  planned the best route for up to 25\u00A0waypoints.`,
			button: `See High Scores`,
		},
	},
};

copy[SCENES.SCORE] = {
	head: `High Scores`,
	button: `Back to Start`,
};

export default copy;
