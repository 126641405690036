export default [
	`ANL`,
	`ASS`,
	`BCH`,
	`BUM`,
	`CLT`,
	`CNT`,
	`COK`,
	`CUM`,
	`DCK`,
	`DIE`,
	`DIK`,
	`DIX`,
	`DMN`,
	`DYK`,
	`FAG`,
	`FAP`,
	`FCK`,
	`FGT`,
	`FU`,
	`FUC`,
	`FUK`,
	`FUQ`,
	`FUX`,
	`GAI`,
	`GAY`,
	`GEI`,
	`GEY`,
	`GZZ`,
	`HOR`,
	`JAP`,
	`JEW`,
	`JIZ`,
	`JYZ`,
	`JZZ`,
	`KKK`,
	`KLT`,
	`KNT`,
	`KOK`,
	`KOX`,
	`KUM`,
	`LSD`,
	`NAD`,
	`NGR`,
	`NIG`,
	`NUT`,
	`PCP`,
	`PEE`,
	`PIS`,
	`PMS`,
	`PNS`,
	`POO`,
	`POT`,
	`PSS`,
	`PUS`,
	`SAC`,
	`SAK`,
	`SEX`,
	`SHT`,
	`STD`,
	`SUC`,
	`SUK`,
	`SXE`,
	`SXI`,
	`SXX`,
	`TIT`,
	`TWT`,
	`VAG`,
	`VAJ`,
	`VGN`,
	`VJN`,
	`WOP`,
	`WTF`,
	`XTC`,
	`XXX`,
];
