import { Stage, Layer, Line, Path } from "konva";
import Anime from "animejs";
import { interpolatePath } from "d3-interpolate-path";

import hagen from "hagen";

import Icon from "./icon";
import Sensor from "./sensor";
import Marker from "./marker";

import * as ACTIONS from "../state/actions";
import {
	arrayTo2D,
	createSVG,
	findSrc as findSource,
	withinEllipse,
} from "../libs/utils";

import COLORS from "@deeplocal/colors/google";
import { NUM_MARKERS } from "../etc/config";

export default class Canvas {
	constructor(manager) {
		hagen.log(`CANVAS`, `Creating canvas...`);

		// ========= STAGE =========
		this.manager = manager;
		this.stage = new Stage({
			container: `canvas`,
			width: window.innerWidth,
			height: window.innerHeight,
		});

		// ========= LAYERS =========

		// create a new layer/line for fingerpainting
		[this.touchLayer, this.touchLine] = this.createLayerLine({
			stroke: COLORS.defaults.yellow,
			dash: [10, 20],
		});

		// create a new layer/line for GMP route
		[
			this.directionsLayer,
			this.directionsLine,
		] = this.createLayerLine({
			stroke: COLORS.defaults.blue,
		});
		this.directionsLayer.position({ x: 3, y: 2 });

		// create a new layer/line for user route
		this.routeLayer = this.createLayer();
		this.routeLine = this.createLine({
			layer: this.routeLayer,
			stroke: `#00ff00`,
		});
		this.firstHalfRouteLine = this.createLine({
			layer: this.routeLayer,
			stroke: `#ff00ff`,
		});
		this.secondHalfRouteLine = this.createLine({
			layer: this.routeLayer,
			stroke: `#ff0000`,
		});
		this.secondHalfOptimizedRouteLine = this.createLine({
			layer: this.routeLayer,
			stroke: `#00ffff`,
		});
		this.routeLayer.position({ x: -2, y: -3 });

		// ========= ICONS =========
		this.iconLayer = this.createLayer();

		// ========= MARKERS =========
		this.markerLayer = this.createLayer();
		this.markers = [];

		// this.sensorLayer = this.createLayer();
		this.sensors = [];

		// ========= START =========

		this.fingerPaint();
	}

	// ========= UTILITY =========

	setLinesColor({ lines, color }) {
		lines.forEach((line) => line.stroke(color));
	}

	// create both a konva layer and line
	createLayerLine({ stroke, dash }) {
		const layer = this.createLayer();
		const line = this.createLine({ layer, stroke, dash });

		return [layer, line];
	}

	// create konva layer
	createLayer() {
		const layer = new Layer();
		this.stage.add(layer);
		return layer;
	}

	// create a konva line within a layer
	createLine({ layer, stroke, dash = [] }) {
		const line = new Line({
			points: [],
			lineCap: `round`,
			lineJoin: `round`,
			stroke,
			strokeWidth: 7.5,
			dash,
		});
		layer.add(line);

		return line;
	}

	// clear a layer and line
	clearDrawing({ layer, line }) {
		layer.clear(); // clear the previous drawing
		line.points([]); // clear all points in the line
	}

	// ========= LINES =========

	// draw the touched line
	fingerPaint() {
		hagen.log(`CANVAS`, `Listening for touches`);

		let isPainting;
		let canPaint = true;

		// listen for touch start
		this.stage.on(`mousedown touchstart`, () => {
			if (isPainting || !canPaint) {
				return;
			}

			isPainting = true; // set the painting bool

			// set painting status in store
			this.manager.store.dispatch(
				ACTIONS.setHasStartedPainting(true)
			);
			this.manager.store.dispatch(
				ACTIONS.setIsPainting(isPainting)
			);

			// set doneDrawing status in store
			this.manager.store.dispatch(ACTIONS.setDoneDrawing(false));

			// clear the existing drawings
			this.clearDrawing({
				layer: this.touchLayer,
				line: this.touchLine,
			});
			this.clearDrawing({
				layer: this.routeLayer,
				line: this.routeLine,
			});

			// clear all marker illumination
			this.markers.forEach((marker) => {
				marker.unilluminate();
			});

			// unset the drawing order
			this.manager.order = [];

			// add the start position as points to the line
			const start = this.manager.points[0];
			this.touchLine.points(
				this.touchLine.points().concat([start.x, start.y])
			);

			// illuminate the start marker
			this.markers[0].illuminate();

			// draw the updated line
			this.touchLayer.draw();
		});

		// listen for touch end
		this.stage.addEventListener(`mouseup touchend`, async () => {
			if (!isPainting || !canPaint) {
				return;
			}

			isPainting = false; // set the painting bool
			canPaint = false;

			// set doneDrawing status in store
			this.manager.store.dispatch(
				ACTIONS.setIsPainting(isPainting)
			);

			// add the end position as points to the line
			const end = this.manager.points[NUM_MARKERS - 1];
			this.touchLine.points(
				this.touchLine.points().concat([end.x, end.y])
			);

			// illuminate the end marker
			this.markers[NUM_MARKERS - 1].illuminate();

			// draw the updated line
			this.touchLayer.draw();

			// convert the drawn line to roads
			if (this.touchLine.points().length > 0)
				await this.drawRoute(this.manager.order);

			// unlock the next button
			this.manager.store.dispatch(ACTIONS.setDoneDrawing(true));

			canPaint = true;
		});

		// listen for touch position
		this.stage.addEventListener(`mousemove touchmove`, () => {
			// ignore if a touch hasn't started
			if (!isPainting || !canPaint) {
				return;
			}

			// get the touch position
			const pos = this.stage.getPointerPosition();

			this.sensors[0].touch(pos.x, pos.y);

			// if the point is inside the sensor radius, illuminate the marker
			this.sensors.forEach((sensor) => {
				const isInside = withinEllipse({
					x: pos.x,
					y: pos.y,
					h: sensor.sensor.x(),
					k: sensor.sensor.y(),
					rx: sensor.sensor.radiusX(),
					ry: sensor.sensor.radiusY(),
				});

				if (isInside) sensor.touch();
			});

			// add the position as points to the line
			this.touchLine.points(
				this.touchLine.points().concat([pos.x, pos.y])
			);

			// draw the updated line
			this.touchLayer.draw();
		});
	}

	// draw the rerouted line
	async drawRoute(order) {
		hagen.log(`CANVAS`, `Creating route from drawn path`);

		// set the first and last items to the start and end points
		if (order[0] !== 0) order.unshift(0);
		if (order[order.length] !== NUM_MARKERS - 1)
			order.push(NUM_MARKERS - 1);

		// if the line is long enough, split it
		// const halfLength =
		// 	order.length > MIN_LENGTH_FOR_OPTIMIZATION
		// 		? MIN_LENGTH_FOR_OPTIMIZATION
		// 		: order.length;
		const halfLength = Math.floor(order.length / 2) - 1;
		const rightSide = [...order];
		const leftSide = rightSide.splice(
			0,
			halfLength === 0 ? 1 : halfLength
		);
		leftSide.push(rightSide[0]);

		// if the line isn't long enough, make sure the second half still has a start and end
		if (rightSide.length === 1) {
			rightSide.push(rightSide[0]);
		}

		// add missing waypoints to the optimized side
		const missingWaypoints = [];
		for (let i = 0; i < NUM_MARKERS; i++) {
			if (!order.includes(i)) missingWaypoints.push(i);
		}
		const end = [...rightSide].pop();
		const optimizedRightSide = rightSide.concat(missingWaypoints);
		optimizedRightSide.push(end);

		// get the player directions
		this.firstHalfDirections = await this.manager.getDirections({
			order: leftSide,
			shouldOptimize: false,
			shouldDraw: false,
			player: `FIRST`,
		});

		this.secondHalfDirections = await this.manager.getDirections({
			order: rightSide,
			shouldOptimize: false,
			shouldDraw: false,
			player: `SECOND`,
		});

		this.secondHalfOptimizedDirections = await this.manager.getDirections(
			{
				order: optimizedRightSide,
				shouldOptimize: true,
				shouldDraw: false,
				player: `OPTIMIZED`,
			}
		);

		// draw the route
		this.fullDirectionsForShow = [
			...this.firstHalfDirections,
		].concat([...this.secondHalfDirections]);
		await this.morph({
			fromLayer: this.touchLayer,
			fromLine: this.touchLine,
			toPts: this.fullDirectionsForShow,
			line: this.routeLine,
			layer: this.routeLayer,
		});
	}

	// assign points to the route lien halves
	setHiddenRouteLines() {
		// set the lines for the other routes
		this.firstHalfRouteLine.points(this.firstHalfDirections);
		this.secondHalfRouteLine.points(this.secondHalfDirections);
		this.secondHalfOptimizedRouteLine.points(
			this.secondHalfOptimizedDirections
		);

		this.routeLine.hide();
		this.secondHalfOptimizedRouteLine.hide();
		this.firstHalfRouteLine.show();
		this.secondHalfRouteLine.show();
		this.routeLayer.draw();
	}

	// draw the directions line
	async drawDirections(points) {
		hagen.log(`CANVAS`, `Creating route from ideal path`);

		// update points of line
		this.directionsLine.points(points);

		// draw the line
		await this.handDraw({
			points,
			line: this.directionsLine,
			layer: this.directionsLayer,
		});
	}

	// morph one line to another
	async morph({ fromLayer, fromLine, toPts, line, layer }) {
		hagen.log(`CANVAS`, `Morphing from one line to another`);

		// get points
		const fromPts = fromLine.points();

		// convert to 2D array
		const fromPts2D = arrayTo2D(fromPts);
		const toPts2D = arrayTo2D(toPts);

		// create svgs
		const fromPtsSVG = createSVG(fromPts2D);
		const toPtsSVG = createSVG(toPts2D);

		// create animation interpolator
		const interpolator = interpolatePath(fromPtsSVG, toPtsSVG);

		// clear the existing path
		this.clearDrawing({
			layer: fromLayer,
			line: fromLine,
		});

		// animate morph

		const targets = { t: 0 };
		await Anime({
			easing: `easeInOutQuint`,
			duration: 500,
			targets,
			t: 1,
			update: () => {
				// get path at eased time
				const string = interpolator(targets.t);

				// convert the svg back to usable points
				const path = string.slice(1).split(`L`);
				const points = [];
				path.forEach((point) => {
					const split = point.split(`,`);
					points.push(parseInt(split[0]));
					points.push(parseInt(split[1]));
				});

				// update the points in the konva line
				line.points(points);

				// redraw the canvas
				layer.draw();
			},
		}).finished;

		hagen.log(`CANVAS`, `Done morphing`);
	}

	// animate a line drawing through a path
	async handDraw({ points, line, layer, reverse = false }) {
		hagen.log(`CANVAS`, `Animating line drawing`);

		line.show();

		// convert to 2D array
		const points2D = arrayTo2D(points);

		// create svgs
		const svg = createSVG(points2D);

		// get path length in pixels
		const lineLength = new Path({ data: svg }).getLength() * 1.1;

		// draw invisible line
		layer.clear();
		line.points(points);

		// aniamte stroke line draw
		const targets = { t: reverse ? 1 : 0 };
		await Anime({
			easing: `easeInOutSine`,
			duration: 2000,
			targets,
			t: reverse ? 0 : 1,
			update: () => {
				// fake hand drawing by adjusting dash length
				// (dash starts at 0, space starts at length)
				// (dash ends at length, space ends at 0)
				const value = lineLength * targets.t;
				line.dash([value, lineLength - value]);

				// redraw the canvas
				layer.draw();
			},
		}).finished;

		hagen.log(`CANVAS`, `Done drawing line`);
	}

	// ========= MARKERS =========

	addMarker(options) {
		// create a marker
		const options_ = { ...options };
		options_.layer = this.markerLayer;
		const marker = new Marker(options_);
		this.markers.push(marker);

		// create a sensor
		const sensor = new Sensor({
			position: options_.position,
			layer: this.markerLayer,
			marker,
			index: options.index,
			manager: this.manager,
		});
		this.sensors.push(sensor);
	}

	// ========= ICONS =========

	createIcons() {
		if (this.icons === undefined) {
			// create the player icon
			this.playerIcon = new Icon({
				stage: this.stage,
				layer: this.iconLayer,
				name: `PLAYER`,
				imageSrc: this.getIcons(`guest`),
				sensors: this.sensors,
				store: this.manager.store,
				canvas: this,
			});

			// create the cloud icon
			this.cloudIcon = new Icon({
				stage: this.stage,
				layer: this.iconLayer,
				name: `CLOUD`,
				imageSrc: this.getIcons(`cloud`),
				sensors: this.sensors,
				store: this.manager.store,
				canvas: this,
			});

			// create the alert icon
			this.alertIcon = new Icon({
				stage: this.stage,
				layer: this.markerLayer,
				name: `ALERT`,
				imageSrc: {
					construction: findSource(
						this.manager.images,
						`construction`
					),
					accident: findSource(
						this.manager.images,
						`accident`
					),
					traffic: findSource(this.manager.images, `traffic`),
				},
				sensors: this.sensors,
				store: this.manager.store,
				width: 70,
				height: 70,
				canvas: this,
			});

			this.icons = [this.playerIcon, this.cloudIcon];
		} else {
			// create the player icon
			this.playerIcon.imageSrc = this.getIcons(`guest`);

			// create the cloud icon
			this.cloudIcon.imageSrc = this.getIcons(`cloud`);
		}
	}

	getIcons(type) {
		const { business } = this.manager.store.getState();

		return {
			NE: findSource(
				this.manager.images,
				business[type].raceIcons.upRight
			),
			NW: findSource(
				this.manager.images,
				business[type].raceIcons.upLeft
			),
			SE: findSource(
				this.manager.images,
				business[type].raceIcons.downRight
			),
			SW: findSource(
				this.manager.images,
				business[type].raceIcons.downLeft
			),
		};
	}

	// =========  =========
	positionAlertIcon(type) {
		this.alertIcon.changeAlertIcon(type);

		this.alertIcon.icon.moveToTop();
		const pts = this.firstHalfRouteLine.points();
		this.alertIcon.icon.show();
		this.alertIcon.icon.position({
			x: pts[pts.length - 2],
			y: pts[pts.length - 1] - this.alertIcon.icon.height() / 4,
		});

		this.alertIcon.icon.draw();
	}

	changeSecondHalfColor() {
		this.setLinesColor({
			lines: [this.secondHalfRouteLine],
			color: COLORS.red[`900`],
		});
		this.routeLayer.draw();
	}

	clearRouteLineForGhost() {
		this.routeLine.points([]);
		this.setLinesColor({
			lines: [this.routeLine],
			color: COLORS.grey[`400`],
		});
		this.routeLine.moveToTop();
		this.routeLine.show();
	}

	drawGhost(x, y) {
		// add the position as points to the line
		this.routeLine.points(this.routeLine.points().concat([x, y]));

		// draw the updated line
		this.routeLayer.draw();
	}
}
