// ========= REACT =========
import React from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import PopUp from "../PopUp";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import BUSINESSES from "../../etc/businesses";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY, { replaceTokenWithText } from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const GoButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};

		border-radius: var(--radius);
		/* border: 2px solid ${COLORS.defaults.yellow}; */

		color: white;
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		width: calc(var(--u) * 50);

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}
		
		text-transform: unset;
	}
`;

const Versus = styled.div`
	display: flex;
	width: 100%;
	height: 2em;
	justify-content: space-between;
	align-items: flex-start;
`;

const Icon = styled(Img)`
	position: absolute;
	margin: calc(var(--margin) * -1);
	width: calc(var(--u) * 50);
	border-radius: var(--radius);
`;

const Headline = styled.h2`
	color: ${COLORS.defaults.yellow};
	text-transform: uppercase;
	font-family: acumin-pro, sans-serif;
	font-weight: 900;
	font-style: italic;
	line-height: calc(var(--u) * 17);
	font-size: calc(var(--u) * 40);

	padding: calc(var(--margin) * 2);
	margin-bottom: calc(var(--margin) * 1.75);
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 0 calc(var(--margin) * 3);
`;

// ========= MAIN =========
const Ready = ({ manager, business, onButtonClick }) => {
	return (
		<PopUp
			top={
				<Versus>
					<Icon
						fluid={findFluidGatsbyImage(
							manager.images,
							business.guest.heroImg
						)}
					/>
					<h4>{COPY[SCENES.READY].head}</h4>
					<Icon
						fluid={findFluidGatsbyImage(
							manager.images,
							business.cloud.heroImg
						)}
					/>
				</Versus>
			}
			bottom={
				<Bottom>
					<Headline>VS</Headline>
					<p>
						{replaceTokenWithText(
							COPY[SCENES.READY].body,
							business.vehicle
						)}
					</p>
					<GoButton onClick={onButtonClick}>
						{COPY[SCENES.READY].button}
					</GoButton>
				</Bottom>
			}
		/>
	);
};

export default connect(
	(state) => {
		return {
			business: state.business,
		};
	},
	(dispatch) => {
		return {
			onButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.RACE)),
		};
	}
)(Ready);
