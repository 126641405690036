// ========= REACT =========
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import Img from "gatsby-image";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

// ========= UTILITIES =========
import {
	findFluidGatsbyImage,
	createStars,
	createCurrency,
} from "../../libs/utils";
import Anime from "animejs";
import { getScores, saveUser } from "../../app/firebase";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import * as ACTIONS from "../../state/actions";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Panels = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PanelContainer = styled.div`
	width: 30%;
	margin: calc(var(--margin) / 4);
	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);
	background: white;
	border-radius: var(--radius);
`;

const PanelTop = styled.div`
	background: ${(props) => props.color};
	color: white;
	padding: var(--margin);
	padding-bottom: calc(var(--margin) * 1.5);
	border-radius: var(--radius) var(--radius) 0 0;
	text-align: center;
	font-weight: bold;
`;

const PanelIcon = styled(Img)`
	width: calc(var(--u) * 30);
	margin-top: calc(calc(var(--u) * 30) / -2);
	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);
`;

const PanelRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
	font-size: 1.5rem;

	& > * {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40%;
		text-align: center;
	}

	& .colored {
		color: ${(props) => props.color};
	}
	& .medium {
		font-size: 1.2rem;
	}
	& .large {
		font-size: 2rem;
	}
`;

const PanelMiddle = styled.div`
	background: white;
	color: ${COLORS.defaults.grey};
	padding: calc(var(--margin) / 3);
	padding-bottom: var(--margin);

	border-bottom: 2px solid ${COLORS.grey[`100`]};

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	height: 200px;
`;

const PanelBottom = styled.div`
	background: white;
	color: ${COLORS.defaults.grey};
	padding: calc(var(--margin) / 4);
	border-radius: 0 0 var(--radius) var(--radius);

	text-align: center;

	line-height: 0;
	font-size: 1.5rem;

	font-weight: bold;

	h2 {
		font-size: 5rem;
		color: ${(props) => props.color};
	}
`;

const NextButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};
		border-radius: var(--radius);

		pointer-events: auto;

		color: ${COLORS.grey[`50`]};
		font-size: 1.5em;
		text-transform: unset;

		margin: calc(var(--u) * 4);
		padding: 0 calc(var(--u) * 4);

		height: calc(var(--u) * 10);
		width: calc(var(--u) * 50);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}
	}
`;

const Panel = ({ title, color, icon, stars, profit, score }) => {
	return (
		<PanelContainer>
			<PanelTop color={color}>
				<h4>{title}</h4>
			</PanelTop>

			<PanelMiddle>
				<PanelIcon fluid={icon} />
				<PanelRow color={color}>
					<div>Efficiency</div>
					<div className="colored medium">
						{createStars({ rating: stars })}
					</div>
				</PanelRow>
				<PanelRow>
					<div />
					<div className="large">×</div>
				</PanelRow>
				<PanelRow color={color}>
					<div>Revenue</div>
					<div className="colored large">
						{createCurrency({ value: profit })}
					</div>
				</PanelRow>
			</PanelMiddle>

			<PanelBottom color={color}>
				<p>Score</p>
				<h2>{score}</h2>
			</PanelBottom>
		</PanelContainer>
	);
};

// ========= MAIN =========
const Compare = ({
	manager,
	business,
	player,
	cloud,
	onCompareDone,
	onSetWin,
	onBumpPlayer,
}) => {
	// animate individual values
	const [t, setT] = useState(0);
	useEffect(() => {
		Anime({
			duration: 1000,
			delay: 250,
			easing: `linear`,
			update: (anim) => {
				setT(anim.progress / 100);
			},
		});
	}, []);

	// retreive high scores
	useEffect(() => {
		// check if the player won
		const playerScore = player.stars * player.profit;
		const cloudScore = cloud.stars * cloud.profit;

		if (playerScore === cloudScore) onBumpPlayer();

		let win = false;
		if (playerScore > cloudScore) {
			win = true;
		}

		// get scores from firebase, async
		const fetchData = async () => {
			const result = await getScores();

			let lowest = result.pop();
			if (lowest === undefined) lowest = { score: 0 };

			// check if player score is a highscore
			let highScore = false;
			if (playerScore > lowest.score) highScore = true;

			onSetWin(win, highScore);
		};
		fetchData();
	}, []);

	return (
		<Container>
			<Panels>
				<Panel
					title={business.name}
					color={business.guest.color}
					icon={findFluidGatsbyImage(
						manager.images,
						business.guest.playerIcon
					)}
					stars={player.stars}
					profit={player.profit}
					score={Math.round(player.stars * player.profit * t)}
				/>

				<Panel
					title="Google Maps Platform"
					color={business.cloud.color}
					icon={findFluidGatsbyImage(
						manager.images,
						business.cloud.playerIcon
					)}
					stars={cloud.stars}
					profit={cloud.profit}
					score={Math.round(cloud.stars * cloud.profit * t)}
				/>
			</Panels>

			<NextButton
				onClick={() => {
					onCompareDone({ business, player, cloud });
				}}
			>
				Next
			</NextButton>
		</Container>
	);
};

export default connect(
	(state) => {
		return {
			business: state.business,
			player: state.player,
			cloud: state.cloud,
		};
	},
	(dispatch) => {
		return {
			onBumpPlayer: () => {
				dispatch(ACTIONS.bumpPlayer());
			},

			onSetWin: (win, highscore) => {
				dispatch(ACTIONS.setWin(win));
				dispatch(ACTIONS.setHighscore(highscore));
			},
			onCompareDone: ({ business, player, cloud }) => {
				saveUser({
					business: business.name,
					cloud: Math.round(cloud.profit * cloud.stars),
					player: Math.round(player.profit * player.stars),
				});

				dispatch(ACTIONS.setScene(SCENES.FINISH));
			},
		};
	}
)(Compare);
