import React from "react";
import { connect } from "react-redux";

import SCENES from "../etc/scenes";

import Start from "./scenes/Start";
import Choose from "./scenes/Choose";
import Tutorial from "./scenes/Tutorial";
import Draw from "./scenes/Draw";
import Ready from "./scenes/Ready";
import Race from "./scenes/Race";
import Compare from "./scenes/Compare";
import Finish from "./scenes/Finish";
import Initials from "./scenes/Initials";
import Score from "./scenes/Score";
import Credits from "./scenes/Credits";

const StateManager = ({ manager, scene }) => {
	switch (scene) {
		case SCENES.START:
			return <Start manager={manager} />;
		case SCENES.CHOOSE:
			return <Choose manager={manager} />;
		case SCENES.TUTORIAL:
			return <Tutorial manager={manager} />;
		case SCENES.DRAW:
			return <Draw manager={manager} />;
		case SCENES.READY:
			return <Ready manager={manager} />;
		case SCENES.RACE:
			return <Race manager={manager} />;
		case SCENES.COMPARE:
			return <Compare manager={manager} />;
		case SCENES.FINISH:
			return <Finish manager={manager} />;
		case SCENES.INITIALS:
			return <Initials manager={manager} />;
		case SCENES.SCORE:
			return <Score manager={manager} />;
		case SCENES.CREDITS:
			return <Credits manager={manager} />;
		default:
			return <div />;
	}
};

export default connect((state) => {
	return {
		scene: state.scene,
	};
})(StateManager);
