// reload the page when a specific touch pattern is touched
if (typeof window !== `undefined`) {
	// ========= TOUCHES =========
	window.addEventListener(
		`touchstart`,
		(ev) => {
			const RADIUS = 200;
			if (ev.touches.length === 2) {
				const touch0 = ev.touches[0];
				const touch1 = ev.touches[1];

				// RESTART APP =========
				if (
					touch0.screenX < RADIUS && // top left corner
					touch0.screenY < RADIUS &&
					touch1.screenX > screen.width - RADIUS && // top right corner
					touch1.screenY < RADIUS
				) {
					// location.reload();
					window.location.href = `/`;
				}

				// // GO TO HIGHSCORES =========
				// if (
				// 	touch0.screenX < RADIUS && // bottom left corner
				// 	touch0.screenY > screen.height - RADIUS &&
				// 	touch1.screenX > screen.width - RADIUS && // bottom right corner
				// 	touch1.screenY > screen.height - RADIUS
				// ) {
				// 	window.location.href = `/?scene=SCORE`;
				// }

				// GO TO DIRECTORY =========
				if (
					touch0.screenX > screen.width - RADIUS && // top right corner
					touch0.screenY < RADIUS &&
					touch1.screenX > screen.width - RADIUS && // bottom right corner
					touch1.screenY > screen.height - RADIUS
				) {
					window.location.href = `/directory`;
				}
			}
		},
		false
	);
}
