import { resample } from "@thi.ng/geom-resample";

import { promiseSerial, chunk, deepFlatten } from "./utils";
import { API_KEY } from "../etc/config";

// convert the touch line on the canvas to snapped roads
export async function canvasToMap({ manager }) {
	const { canvas } = manager;

	// get the points from the touch line
	const points = canvas.touchLine.points();

	// convert points to 2d array
	const points2D = [];
	while (points.length) points2D.push(points.splice(0, 2));

	// evenly distribute points along line
	const evenPoints = resample(points2D, { dist: 25 }, false, true);
	const flattenedPoints = deepFlatten(evenPoints);

	// reset points in drawn line as redistributed points
	canvas.touchLine.points(flattenedPoints);

	if (flattenedPoints.length > 0) {
		// convert canvas pixels to latLng
		const latLngPoints = convertPointsToLatLng({
			manager,
			canvas,
			points: flattenedPoints,
		});

		// split into 100 points
		const chunkedPoints = chunk(latLngPoints, 99);

		// set the last point of each array to the first point of the next one
		chunkedPoints.forEach((pts, i) => {
			if (i !== chunkedPoints.length - 1) {
				pts.push(chunkedPoints[i + 1][0]);
			}
		});

		// snap to roads
		await snapLatLngsToRoads({
			manager,
			canvas,
			chunkedPoints,
		});
	}
}

// snap multiple chunks of points to the canvas in order
async function snapLatLngsToRoads({ manager, canvas, chunkedPoints }) {
	// create an array of promises to fetch snapped points
	const funcs = chunkedPoints.map((points) => () =>
		fetchSnaps({ manager, canvas, points })
	);

	// finish each promise in order and draw the resulting route
	promiseSerial(funcs, (points) => {
		points.unshift(manager.points[0].x, manager.points[0].y);
		points.push(manager.points[1].x, manager.points[1].y);
		canvas.drawRoute(points);
	});
}

// get the snapped roads from an array of points
async function fetchSnaps({ manager, canvas, points }) {
	const interp = true;
	const response = await fetch(
		`https://roads.googleapis.com/v1/snapToRoads?key=${API_KEY}${
			interp ? `&interpolate=true` : ``
		}&path=${points.join(`|`)}`
	);
	const data = await response.json();

	return processSnapResponse({
		manager,
		canvas,
		data,
	});
}

// convert pixels to geo points
export function convertSinglePointToLatLng({ manager, point }) {
	return manager.overlay.projection.fromContainerPixelToLatLng(
		new manager.api.Point(point[0], point[1])
	);
}
export function convertPointsToLatLng({ manager, points }) {
	const pointsCopy = [...points];

	// convert points to 2d array
	const arrangedPoints = [];
	while (pointsCopy.length)
		arrangedPoints.push(pointsCopy.splice(0, 2));

	// get latLng for each point
	const latLngPoints = [];
	arrangedPoints.forEach((point) => {
		const latLng = convertSinglePointToLatLng({ manager, point });
		latLngPoints.push(`${latLng.lat()},${latLng.lng()}`);
	});

	return latLngPoints;
}

// convert geo points to pixels
export function convertSingleLatLngToPoint({ manager, point }) {
	return manager.overlay.projection.fromLatLngToContainerPixel(point);
}
export function convertLatLngToPoints({ manager, latLngs }) {
	// get pixel values for each point
	const pixelPoints = [];
	latLngs.forEach((point) => {
		const pixel = convertSingleLatLngToPoint({ manager, point });

		pixelPoints.push(pixel.x);
		pixelPoints.push(pixel.y);
	});

	return pixelPoints;
}

// store snapped polyline returned by the snap-to-road service.
function processSnapResponse({ manager, data }) {
	const snappedCoordinates = [];
	const placeIdArray = [];

	for (let i = 0; i < data.snappedPoints.length; i++) {
		const latlng = new manager.api.LatLng(
			data.snappedPoints[i].location.latitude,
			data.snappedPoints[i].location.longitude
		);
		snappedCoordinates.push(latlng);
		placeIdArray.push(data.snappedPoints[i].placeId);
	}

	return convertLatLngToPoints({
		manager,
		latLngs: snappedCoordinates,
	});
}
