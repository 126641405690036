import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Img from "gatsby-image";

import { createCurrency, createStars } from "../libs/utils";

import COLORS from "@deeplocal/colors/google";

// ========= STYLE =========

const Container = styled.div`
	width: calc(35 * var(--u));
	/* margin: var(--margin); */

	filter: drop-shadow(0px 1px 16px ${COLORS.grey[`900`]}1c);
`;

const Top = React.memo(styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: calc(18 * var(--u));
	background: ${(props) => props.color};

	border-radius: var(--radius) var(--radius) 0px 0px;
`);

const Icon = React.memo(styled(Img)`
	width: calc(12 * var(--u));
	padding: calc(2 * var(--u)) calc(3 * var(--u));
	margin: calc(1 * var(--u));
`);

const Label = React.memo(styled.span`
	color: white;
	font-size: calc(3 * var(--u));
	padding: calc(1 * var(--u));
`);

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	height: calc(22 * var(--u));
	padding: calc(2 * var(--u));
	border-radius: 0px 0px var(--radius) var(--radius);

	background: white;
	color: ${(props) => props.color};
`;

const Stars = styled.div`
	font-size: calc(3 * var(--u));
	color: ${COLORS.defaults.yellow};
`;

const Profit = styled.span`
	font-size: calc(7 * var(--u));

	${(props) => {
		if (props.isNegative) return `color: ${COLORS.defaults.red}`;
	}}
`;

// ========= COMPONENTS =========

const ScorePanel = ({ color, icon, label, stars, profit }) => {
	return (
		<Container>
			<Top color={color}>
				<Icon fluid={icon} />
				<Label>{label}</Label>
			</Top>
			<Bottom color={color} stars={stars} profit={profit}>
				<Stars>Stats {createStars({ rating: stars })}</Stars>
				<Profit isNegative={profit < 0}>
					{createCurrency({ value: profit })}
				</Profit>
			</Bottom>
		</Container>
	);
};

// ========= UTILITY =========

// ========= PROPS =========

ScorePanel.defaultProps = {
	color: COLORS.defaults.blue,
	stars: 5,
	profit: 0,
};

// ========= REDUX =========

const mapPlayerStateToProps = (state) => {
	return {
		profit: state.player.profit,
		stars: state.player.stars,
	};
};

const mapCloudStateToProps = (state) => {
	return {
		profit: state.cloud.profit,
		stars: state.cloud.stars,
	};
};

const PlayerScorePanel = connect(mapPlayerStateToProps)(ScorePanel);
const CloudScorePanel = connect(mapCloudStateToProps)(ScorePanel);

export { PlayerScorePanel, CloudScorePanel };
