import COLORS from "@deeplocal/colors/google";

export default {
	NONE: {
		state: `NONE`,
		food: `delicious, tasty air`,
		vehicle: `Spaceship`,
		map: {
			lat: 37.779183,
			lng: -122.426798,
			zoom: 14,
		},
		mode: `DRIVING`,
		name: `The Slice is Right`,
		startWaypointLit: `bike_start_waypoint_lit`,
		startWaypointUnlit: `bike_start_waypoint_unlit`,
		endWaypointLit: `bike_end_waypoint_lit`,
		endWaypointUnlit: `bike_end_waypoint_unlit`,
		winnerImg: `truck_win`,
		loserImg: `truck_lose`,
		cloud: {
			color: COLORS.defaults.blue,
			heroImg: `cloud_truck_hero`,
			raceIcons: {
				upLeft: `cloud_truck_upleft`,
				upRight: `cloud_truck_upright`,
				downLeft: `cloud_truck_downleft`,
				downRight: `cloud_truck_downright`,
			},
			playerIcon: `cloud_icon`,
		},
		guest: {
			color: COLORS.defaults.yellow,
			altColor: COLORS.defaults.yellow,
			heroImg: `player_truck_hero`,
			raceIcons: {
				upLeft: `player_truck_upleft`,
				upRight: `player_truck_upright`,
				downLeft: `player_truck_downleft`,
				downRight: `player_truck_downright`,
			},
			playerIcon: `truck_icon`,
		},
	},
	TRUCK: {
		state: `TRUCK`,
		food: `ice cream`,
		vehicle: `Truck`,
		map: {
			lat: 37.779183,
			lng: -122.426798,
			zoom: 15,
		},
		mode: `DRIVING`,
		name: `Scoop Dreams`,
		startWaypointLit: `truck_start_waypoint_lit`,
		startWaypointUnlit: `truck_start_waypoint_unlit`,
		endWaypointLit: `truck_end_waypoint_lit`,
		endWaypointUnlit: `truck_end_waypoint_unlit`,
		winnerImg: `truck_win`,
		loserImg: `truck_lose`,
		cloud: {
			color: COLORS.defaults.blue,
			heroImg: `cloud_truck_hero`,
			raceIcons: {
				upLeft: `cloud_truck_upleft`,
				upRight: `cloud_truck_upright`,
				downLeft: `cloud_truck_downleft`,
				downRight: `cloud_truck_downright`,
			},
			playerIcon: `cloud_icon`,
		},
		guest: {
			color: COLORS.defaults.red,
			altColor: COLORS.defaults.red,
			heroImg: `player_truck_hero`,
			raceIcons: {
				upLeft: `player_truck_upleft`,
				upRight: `player_truck_upright`,
				downLeft: `player_truck_downleft`,
				downRight: `player_truck_downright`,
			},
			playerIcon: `truck_icon`,
		},
	},
	BIKE: {
		state: `BIKE`,
		food: `desserts`,
		vehicle: `Bike`,
		map: {
			lat: 37.779183,
			lng: -122.426798,
			zoom: 16,
		},
		mode: `BICYCLING`,
		name: `Sugar Spinners`,
		startWaypointLit: `bike_start_waypoint_lit`,
		startWaypointUnlit: `bike_start_waypoint_unlit`,
		endWaypointLit: `bike_end_waypoint_lit`,
		endWaypointUnlit: `bike_end_waypoint_unlit`,
		winnerImg: `bike_win`,
		loserImg: `bike_lose`,
		cloud: {
			color: COLORS.defaults.blue,
			heroImg: `cloud_bike_hero`,
			raceIcons: {
				upLeft: `cloud_bike_upleft`,
				upRight: `cloud_bike_upright`,
				downLeft: `cloud_bike_downleft`,
				downRight: `cloud_bike_downright`,
			},
			playerIcon: `cloud_icon`,
		},
		guest: {
			color: COLORS.defaults.green,
			altColor: COLORS.defaults.green,
			heroImg: `player_bike_hero`,
			raceIcons: {
				upLeft: `player_bike_upleft`,
				upRight: `player_bike_upright`,
				downLeft: `player_bike_downleft`,
				downRight: `player_bike_downright`,
			},
			playerIcon: `bike_icon`,
		},
	},
	WALK: {
		state: `WALK`,
		food: `pizza`,
		vehicle: `Scooter`,
		map: {
			lat: 37.779183,
			lng: -122.426798,
			zoom: 17,
		},
		mode: `WALKING`,
		name: `The Slice is Right`,
		startWaypointLit: `walk_start_waypoint_lit`,
		startWaypointUnlit: `walk_start_waypoint_unlit`,
		endWaypointLit: `walk_end_waypoint_lit`,
		endWaypointUnlit: `walk_end_waypoint_unlit`,
		winnerImg: `walk_win`,
		loserImg: `walk_lose`,
		cloud: {
			color: COLORS.defaults.blue,
			heroImg: `cloud_walk_hero`,
			raceIcons: {
				upLeft: `cloud_walk_upleft`,
				upRight: `cloud_walk_upright`,
				downLeft: `cloud_walk_downleft`,
				downRight: `cloud_walk_downright`,
			},
			playerIcon: `cloud_icon`,
		},
		guest: {
			color: COLORS.defaults.yellow,
			altColor: COLORS.defaults.yellow,
			heroImg: `player_walk_hero`,
			raceIcons: {
				upLeft: `player_walk_upleft`,
				upRight: `player_walk_upright`,
				downLeft: `player_walk_downleft`,
				downRight: `player_walk_downright`,
			},
			playerIcon: `walk_icon`,
		},
	},
};
