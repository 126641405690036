// ========= REACT =========
import React from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import PopUp from "../PopUp";

// ========= UTILITIES =========
import { createCurrency, createStars } from "../../libs/utils";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY, { replaceTokenWithText } from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Headline = styled.h4`
	margin: var(--margin) var(--margin) 0 var(--margin);
`;

const Holder = styled.div`
	display: flex;
	align-content: center;
	width: 100%;
	margin: var(--margin) var(--margin) 0 var(--margin);
`;

const Little = styled.div`
	align-self: center;
	width: 30%;
	color: ${COLORS.defaults.yellow};

	.label {
		font-size: 0.75em;
		line-height: 0;
	}

	.currency {
		font-size: 2em;
		line-height: 0;
		margin-bottom: 1em;
	}
`;

const Big = styled.div`
	width: 70%;
	align-self: center;
	text-align: left;
	margin-right: var(--margin);
`;

const NextButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};

		border-radius: var(--radius);
		/* border: 2px solid ${COLORS.defaults.yellow}; */

		color: white;
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		width: calc(var(--u) * 50);

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}
		
		text-transform: unset;
	}
`;

// ========= MAIN =========
const Tutorial = ({ onButtonClick, business }) => {
	return (
		<Container>
			<PopUp
				top={
					<>
						<h3>{COPY[SCENES.TUTORIAL].head}</h3>
					</>
				}
				bottom={
					<>
						<Headline>
							{replaceTokenWithText(
								COPY[SCENES.TUTORIAL].subhead,
								business.food
							)}
						</Headline>
						<Holder>
							<Little>
								{createStars({ rating: 5 })}
							</Little>
							<Big>{COPY[SCENES.TUTORIAL].stars}</Big>
						</Holder>
						<Holder>
							<Little>
								<p className="label">REVENUE</p>
								<p className="currency">
									{createCurrency({ value: 3354 })}
								</p>
							</Little>
							<Big>{COPY[SCENES.TUTORIAL].profit}</Big>
						</Holder>
						<NextButton onClick={onButtonClick}>
							{COPY[SCENES.TUTORIAL].button}
						</NextButton>
					</>
				}
			/>
		</Container>
	);
};

export default connect(
	(state) => {
		return { business: state.business };
	},
	(dispatch) => {
		return {
			onButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.DRAW)),
		};
	}
)(Tutorial);
