// ========= REACT =========
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Button from "@material-ui/core/Button";

// ========= UTILITIES =========
import { saveScore } from "../../app/firebase";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import BADWORDS from "../../libs/badwords";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	.simple-keyboard {
		width: calc(65% + calc(var(--margin) * 2));
		font-family: "Google Sans";
		color: ${COLORS.defaults.grey};
	}

	.simple-keyboard.hg-theme-default.myTheme {
		border: var(--radius) solid ${COLORS.defaults.yellow};
		background: ${COLORS.defaults.yellow};
		border-radius: var(--radius);
	}
`;

const Modal = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 65%;
	height: 20%;
	color: ${COLORS.yellow[`600`]};
	border-radius: var(--radius);
	font-size: 2.5em;
	padding: var(--margin) var(--margin) var(--u) var(--margin);

	background: ${(properties) =>
		properties.alert ? COLORS.red[`50`] : `white`};
	border: ${(properties) =>
		properties.alert
			? `10px solid ${COLORS.defaults.red}`
			: `10px solid transparent`};

	animation: ${(properties) =>
		properties.alert
			? `shake 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97);`
			: ``};
	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-40px, 0, 0);
		}

		30%,
		50%,
		70% {
			transform: translate3d(-10px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(0, 0, 0);
		}
	}
`;

const Char = styled.h1`
	min-width: 30%;
	text-align: center;

	margin-bottom: calc(var(--margin) / 2);

	&.blink {
		border-bottom: 10px solid ${COLORS.yellow[`600`]};
		animation: blink 0.7s steps(2, start) infinite;
	}
	@keyframes blink {
		to {
			visibility: hidden;
		}
	}
`;

const Score = styled.h1`
	color: white;
	font-size: 3em;

	background: ${COLORS.defaults.yellow};
	padding: var(--radius);
	border-radius: var(--radius);
`;

const NextButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};
		border-radius: var(--radius);

		color: ${COLORS.grey[`50`]};
		font-size: 1.5em;

		width: calc(var(--u) * 120);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);
		margin-top: 0;

		padding: 0 calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}

		pointer-events: auto;
		text-transform: unset;
	}
`;

// ========= MAIN =========
const Initials = ({ player, onButtonClick }) => {
	const [playerInitials, setPlayerInitials] = useState(``);
	const keyboardReference = useRef(null);
	const [alert, setAlert] = useState(false);

	useEffect(() => {
		if (alert) {
			setTimeout(() => {
				setAlert(false);
			}, 1000);
		}
	}, [alert]);

	return (
		<Container>
			<Score>Your Score: {player.stars * player.profit}</Score>
			<Modal alert={alert}>
				{[0, 1, 2].map((value) => (
					<Char
						className={
							playerInitials.length === value
								? `blink`
								: ``
						}
						key={`char-${value}`}
					>
						{playerInitials.slice(value, value + 1)}
					</Char>
				))}
			</Modal>

			<Keyboard
				ref={keyboardReference}
				theme="hg-theme-default hg-layout-default myTheme"
				display={{
					"{enter}": `submit`,
					"{bksp}": `undo`,
				}}
				layout={{
					default: [
						`Q W E R T Y U I O P`,
						`A S D F G H J K L`,
						`Z X C V B N M`,
					],
				}}
				buttonTheme={[
					{ class: `hg-yellow`, buttons: `{enter}` },
				]}
				onChange={(input) => {
					let initials = input;
					if (initials.length > 3) {
						initials = input.slice(-1);
						keyboardReference.current.keyboard.setInput(
							initials
						);
					}
					if (BADWORDS.includes(input)) {
						keyboardReference.current.keyboard.clearInput();
						initials = ``;
						setAlert(true);
					}
					setPlayerInitials(initials);
				}}
			/>
			<NextButton
				disabled={playerInitials.length === 0}
				onClick={() => {
					const id = saveScore(
						playerInitials,
						Math.round(player.stars * player.profit)
					);

					onButtonClick(id);
				}}
			>
				Submit
			</NextButton>
		</Container>
	);
};

export default connect(
	(state) => {
		return { player: state.player };
	},
	(dispatch) => {
		return {
			onButtonClick: (id) => {
				dispatch(ACTIONS.setID(id));
				dispatch(ACTIONS.setScene(SCENES.SCORE));
			},
		};
	}
)(Initials);
