import TYPES from "./types";

export function setScene(scene) {
	return {
		type: TYPES.SET_SCENE,
		scene,
	};
}

export function setHasStartedPainting(hasStartedPainting) {
	return {
		type: TYPES.SET_HAS_STARTED_PAINTING,
		hasStartedPainting,
	};
}

export function setIsPainting(isPainting) {
	return {
		type: TYPES.SET_IS_PAINTING,
		isPainting,
	};
}

export function setDoneDrawing(done) {
	return {
		type: TYPES.SET_DONE_DRAWING,
		done,
	};
}

export function setBusiness(business) {
	return {
		type: TYPES.SET_BUSINESS,
		business,
	};
}

export function setPlayer({ stars, profit }) {
	return {
		type: TYPES.SET_PLAYER,
		stars,
		profit,
	};
}

export function setPlayerTraffic(traffic) {
	return {
		type: TYPES.SET_PLAYER_TRAFFIC,
		traffic,
	};
}

export function setCloud({ stars, profit }) {
	return {
		type: TYPES.SET_CLOUD,
		stars,
		profit,
	};
}

export function setWin(win) {
	return {
		type: TYPES.SET_WIN,
		win,
	};
}

export function setHighscore(highscore) {
	return {
		type: TYPES.SET_HIGHSCORE,
		highscore,
	};
}

export function setID(id) {
	return {
		type: TYPES.SET_ID,
		id,
	};
}

export function bumpPlayer() {
	return {
		type: TYPES.BUMP_PLAYER,
	};
}

export function setPlayerTick(tick) {
	return {
		type: TYPES.SET_ICON_TICK,
		tick,
	};
}
