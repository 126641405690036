import hagen from "hagen";
import { Image as KonvaImage } from "konva";
import Anime from "animejs";
import { resample } from "@thi.ng/geom-resample";

import { arrayTo2D, angle, distance } from "../libs/utils";
import * as ACTIONS from "../state/actions";
import Score from "./score";

export default class Icon {
	// add an icon to the map
	constructor({
		stage,
		sensors,
		layer,
		name,
		imageSrc,
		store,
		canvas,
		width = 150,
		height = 150,
	}) {
		this.name = name;
		this.stage = stage;
		this.layer = layer;
		this.sensors = sensors;
		this.sensorsHit = [];

		this.score = new Score();

		this.images = imageSrc;

		this.store = store;

		this.width = width;
		this.height = height;

		this.canvas = canvas;

		// create the base image object
		this.iconImgObj = new Image();
		this.iconImgObj.src =
			typeof this.images.SE !== `undefined`
				? this.images.SE
				: this.images.construction;

		// listen for the icon image to load
		this.iconImgObj.addEventListener(`load`, () => {
			// create the icon
			this.icon = new KonvaImage({
				x: this.stage.width() / 2,
				y: -this.stage.height() / 2,
				image: this.iconImgObj,
				width: this.width,
				height: this.height,
				offset: {
					x: this.width / 2,
					y: this.height / 2,
				},
			});
			this.layer.add(this.icon);
		});
	}

	changeAlertIcon(type) {
		const newImg = new Image();
		newImg.addEventListener(`load`, () => {
			this.icon.image(newImg);
		});
		newImg.src = this.images[type];
	}

	// make an icon travel along a path
	async travel({ path, waypointPercentHit = 1.0, speed = 1000 }) {
		hagen.log(`ICON`, `Sending ${this.name} along path`);

		hagen.log(
			`ICON`,
			`Player ${this.name} hit ${waypointPercentHit *
				100}% of waypoints`
		);

		// get points
		const points = path.points();

		// convert array to 2D
		const array2D = arrayTo2D(points);

		// resample path to have equidistant points
		const resampled = resample(array2D, { dist: 3 }, false);

		// generate keyframes for animation
		const speedMultiplier = 10;
		let totalDuration = 0;
		const keyframes = resampled.map((point, i, arr) => {
			const nextPoint = arr[i + 1];
			const keyframe = { x: point[0], y: point[1] };
			keyframe.duration = 0;
			if (nextPoint !== undefined)
				keyframe.duration =
					(speed / resampled.length) * speedMultiplier;
			totalDuration += keyframe.duration;
			return keyframe;
		});

		// animate the icon position
		const targets = { x: resampled[0][0], y: resampled[0][1] };
		let lastTargets = { x: 0, y: 0 };
		let i = 0;

		let keyframeTimer = 0;
		let keyframeIndex = 0;
		await Anime({
			easing: `linear`,
			targets,
			keyframes,
			update: (anim) => {
				// get the direction vector
				const direction = angle(
					lastTargets.x,
					lastTargets.y,
					targets.x,
					targets.y
				);

				// flip the icon according to direction vector
				switch (true) {
					// NE
					case direction <= Math.PI &&
						direction > Math.PI / 2: {
						const newImg = new Image();
						newImg.addEventListener(`load`, () => {
							this.icon.image(newImg);
						});
						newImg.src = this.images.NE;
						break;
					}

					// SE
					case direction >= -Math.PI &&
						direction < -Math.PI / 2: {
						const newImg = new Image();
						newImg.addEventListener(`load`, () => {
							this.icon.image(newImg);
						});
						newImg.src = this.images.SE;
						break;
					}

					// SW
					case direction >= -Math.PI / 2 && direction < 0: {
						const newImg = new Image();
						newImg.addEventListener(`load`, () => {
							this.icon.image(newImg);
						});
						newImg.src = this.images.SW;
						break;
					}

					// NW
					case direction <= Math.PI / 2 && direction >= 0: {
						const newImg = new Image();
						newImg.addEventListener(`load`, () => {
							this.icon.image(newImg);
						});
						newImg.src = this.images.NW;
						break;
					}

					default:
						break;
				}

				// position the icon
				this.icon.position(targets);
				this.layer.draw();

				// check if icon is inside a sensor radius
				this.checkForSensorCollision();

				// update the score state every nth tick
				if (i % 3 === 0) {
					if (this.name === `CLOUD`) {
						this.store.dispatch(
							ACTIONS.setCloud({
								stars: this.score.stars,
								profit: this.score.profit,
							})
						);
					} else {
						this.store.dispatch(
							ACTIONS.setPlayer({
								stars: this.score.stars,
								profit: this.score.profit,
							})
						);
					}
				}

				// update the tick state every nth tick
				if (i % 3 === 0 && this.name === `PLAYER`) {
					this.store.dispatch(
						ACTIONS.setPlayerTick(
							keyframes.length - keyframeIndex
						)
					);
				}

				// if (this.name === `PLAYER`)
				// 	console.log({
				// 		timer: keyframeTimer,
				// 		thisDuration: keyframes[keyframeIndex].duration,
				// 		timerPlus:
				// 			keyframeTimer +
				// 			keyframes[keyframeIndex].duration,
				// 		current: anim.currentTime,
				// 		i: keyframes.length - keyframeIndex,
				// 	});

				// keep track of the current keyframe index
				while (
					anim.currentTime >
					keyframeTimer + keyframes[keyframeIndex].duration
				) {
					keyframeTimer += keyframes[keyframeIndex].duration;
					keyframeIndex++;
				}

				// keep track of trajectory
				lastTargets = { ...targets };

				// tick
				this.score.tick();
				i++;
			},
			complete: () => {
				// when done, update the score a final time
				if (this.name === `CLOUD`) {
					this.store.dispatch(
						ACTIONS.setCloud({
							stars: this.score.stars,
							profit: this.score.profit,
						})
					);
				} else {
					this.store.dispatch(
						ACTIONS.setPlayer({
							stars: this.score.stars,
							profit: this.score.profit,
						})
					);
				}
			},
		}).finished;

		hagen.log(`ICON`, `${this.name} finished travelling`);

		return totalDuration;
	}

	checkForSensorCollision() {
		this.sensors.forEach((sensor, i) => {
			const dist = distance(
				this.icon.position().x,
				this.icon.position().y,
				sensor.position[0],
				sensor.position[1]
			);

			if (dist < sensor.raceRadius) {
				if (this.sensorsHit.indexOf(i) === -1) {
					this.sensorsHit.push(i);
					this.score.hitWaypoint();
					sensor.marker.bump();
					if (this.name === `PLAYER`)
						sensor.marker.illuminate();
				}
			}
		});
	}
}
