const scenes = Object.freeze({
	START: `START`,
	CHOOSE: `CHOOSE`,
	TUTORIAL: `TUTORIAL`,
	DRAW: `DRAW`,
	READY: `READY`,
	RACE: `RACE`,
	COMPARE: `COMPARE`,
	FINISH: `FINISH`,
	INITIALS: `INITIALS`,
	SCORE: `SCORE`,
	CREDITS: `CREDITS`,
});

export default scenes;
