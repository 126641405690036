// ========= REACT =========
import React from "react";
import { useRefreshAfterIdle } from "../hooks";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import PopUp from "../PopUp";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	padding: var(--margin);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	/* TEMP: remove this if site gets published on the web */
	a {
		cursor: not-allowed;
		pointer-events: none;
	}
`;

const Icons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* justify-content: space-between; */
	/* width: 100%; */
	margin-top: var(--margin);
`;

const Icon = styled(Img)`
	display: inline-block;
`;

const List = styled.ul`
	list-style: none;
	text-align: left;
`;

const Technology = styled.a`
	color: ${COLORS.grey[`600`]};
	font-weight: bold;
`;

const Description = styled.span`
	color: ${COLORS.grey[`400`]};
	font-style: italic;
	line-height: 0;

	&:before {
		content: " / ";
	}
`;

// ========= MAIN =========
const Credits = ({ manager }) => {
	useRefreshAfterIdle(15000);
	return (
		<PopUp
			top={<h1>Technology</h1>}
			bottom={
				<Container>
					<List>
						<li>
							<Technology href="https://reactjs.org/">
								React
							</Technology>
							<Description>
								Front-end framework
							</Description>
						</li>
						<li>
							<Technology href="https://www.gatsbyjs.org/">
								Gatsby
							</Technology>
							<Description>
								Static Site generator
							</Description>
						</li>
						<li>
							<Technology href="https://konvajs.org/">
								Konva
							</Technology>
							<Description>Canvas library</Description>
						</li>
						<li>
							<Technology href="https://Technologynimejs.com/">
								Anime.js
							</Technology>
							<Description>Animation library</Description>
						</li>
						<li>
							<Technology href="https://firebase.google.com/">
								Firebase
							</Technology>
							<Description>
								Real-time database
							</Description>
						</li>

						<li>
							<Technology href="https://cloud.google.com/Technologyppengine/">
								Google App Engine
							</Technology>
							<Description>Site Hosting</Description>
						</li>

						<li>
							<Technology href="https://cloud.google.com/maps-platform/">
								Google Maps Platform
							</Technology>
							<Description>
								Solutions for Google Maps
							</Description>
						</li>

						<li>
							<Technology href="https://developers.google.com/maps/documentation/javascript/tutorial">
								Maps Javascript API
							</Technology>
							<Description>
								Creation and management of map
							</Description>
						</li>
						<li>
							<Technology href="https://developers.google.com/places/web-service/intro">
								Places API
							</Technology>
							<Description>Create waypoints</Description>
						</li>
						<li>
							<Technology href="https://developers.google.com/maps/documentation/roads/intro">
								Roads API
							</Technology>
							<Description>
								Snap drawn points to real roads
							</Description>
						</li>
						<li>
							<Technology href="https://developers.google.com/maps/documentation/directions/start">
								Directions API
							</Technology>
							<Description>
								Get directions between waypoints
							</Description>
						</li>
					</List>

					<Icons>
						<a href="https://cloud.google.com/">
							<Icon
								style={{ width: `calc(var(--u) * 15)` }}
								fluid={findFluidGatsbyImage(
									manager.images,
									`google-cloud-logo`
								)}
							/>
						</a>
						<FontAwesomeIcon
							style={{ padding: `0 0.5em 0 1.25em` }}
							icon="times"
						/>
						<a href="http://deeplocal.com/">
							<Icon
								style={{ width: `calc(var(--u) * 40)` }}
								fluid={findFluidGatsbyImage(
									manager.images,
									`deeplocal-logo`
								)}
							/>
						</a>
					</Icons>
				</Container>
			}
		/>
	);
};

export default Credits;
