import { useEffect } from "react";

// eslint-disable-next-line import/prefer-default-export
export function useRefreshAfterIdle(timer = 60000) {
	useEffect(() => {
		// restart the app after time
		const refreshTimeout = setTimeout(() => {
			window.location.href = `/`;
		}, timer);

		// cleanup
		return () => {
			clearTimeout(refreshTimeout);
		};
	}, []);
}
