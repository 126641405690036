import { Image as KonvaImage } from "konva";
import hagen from "hagen";
import Anime from "animejs";

export default class Marker {
	constructor({
		index,
		position,
		unlitImageSrc,
		litImageSrc,
		layer,
		width = 100,
		height = 100,
	}) {
		this.index = index;

		this.width = width;
		this.height = height;

		this.unlitImageSrc = unlitImageSrc;
		this.litImageSrc = litImageSrc;

		this.layer = layer;

		this.layer.markersHit = 0;

		// create the base unlit image object
		this.unlitMarkerImgObj = new Image();
		this.unlitMarkerImgObj.src = this.unlitImageSrc;

		// create the base lit image object
		this.litMarkerImgObj = new Image();
		this.litMarkerImgObj.src = this.litImageSrc;

		this.marker = new KonvaImage({
			x: position[0] - this.width / 2,
			y: -200,
			width: this.width,
			height: this.height,
			shadowColor: `black`,
			shadowBlur: 13,
			shadowOffset: { x: 0, y: 7 },
			shadowOpacity: 0.1,
		});
		this.layer.add(this.marker);

		// listen for the marker image to load
		this.unlitMarkerImgObj.addEventListener(`load`, () => {
			// create the marker
			this.marker.image(this.unlitMarkerImgObj);

			// animate dropping
			const targets = { y: 0 };
			Anime({
				targets,
				y: position[1] - this.height / 2,
				round: 1,
				duration: 200,
				easing: `easeOutBack`,
				update: () => {
					this.marker.y(targets.y);
					this.layer.draw();
				},
			});
		});
	}

	unilluminate() {
		this.marker.image(this.unlitMarkerImgObj);
		this.layer.draw();
	}

	illuminate() {
		hagen.log(`SENSOR`, `Marker touched`);

		// change the image
		this.marker.image(this.litMarkerImgObj);

		// bump the icon
		this.bump();

		// add to global count
		this.layer.markersHit++;
	}

	bump() {
		const targets = { y: this.marker.y() };
		Anime({
			targets,
			y: this.marker.y() - 30,
			duration: 200,
			easing: `easeInBack`,
			direction: `alternate`,
			update: () => {
				this.marker.y(targets.y);
				this.layer.draw();
			},
		});
	}
}
