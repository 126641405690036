import React from "react";
import hagen from "hagen";
import ReactGoogleMapLoader from "react-google-maps-loader";

import Head from "./Head";
import StateManager from "./StateManager";

import Manager from "../app/manager";
import Canvas from "../app/canvas";

import { API_KEY } from "../etc/config";

const MapLoader = ({ store, images }) => {
	return (
		<>
			<Head title="MAPS" />

			<div id="wrapper">
				<div id="map" />
				<div id="canvas" />
			</div>

			<ReactGoogleMapLoader
				params={{
					key: API_KEY,
				}}
				render={(api) => {
					const { business } = store.getState();

					if (api) {
						hagen.log(`API`, `Google Maps API loaded`);

						hagen.log(`MAIN`, `Creating page elements...`);

						const manager = new Manager({
							api,
							images,
							store,
							lat: business.map.lat,
							lng: business.map.lng,
							zoom: business.map.zoom,
						});
						manager.canvas = new Canvas(manager);

						return <StateManager manager={manager} />;
					}
					return null;
				}}
			/>
		</>
	);
};

export default MapLoader;
