import {
	NUM_MARKERS,
	EARNED_PER_WAYPOINT,
	SPEND_PER_TICK,
} from "../etc/config";
const MAX_STARS = 5;
const STARS_PER_MARKER = MAX_STARS / NUM_MARKERS;

export default class Score {
	constructor() {
		this.currentLocation = 0;
		this.numberOfWaypointsHit = 0;
	}

	// return number of stars currently earned to the nearest 0.5
	get stars() {
		return (
			Math.round(
				STARS_PER_MARKER * this.numberOfWaypointsHit * 2
			) / 2
		);
	}

	// get current revenue - costs
	get profit() {
		const revenue = this.numberOfWaypointsHit * EARNED_PER_WAYPOINT;
		const costs = this.currentLocation * SPEND_PER_TICK;
		return revenue - costs;
	}

	// increment the current location along the line
	tick() {
		this.currentLocation++;
	}

	// increment the current number of waypoints that have been visited
	hitWaypoint() {
		this.numberOfWaypointsHit++;
	}
}
