import { Circle, Ellipse } from "konva";

import { getURLParameterByName } from "../libs/utils";

export default class Sensor {
	constructor({ position, layer, marker, index, manager }) {
		this.radius = 40;
		this.raceRadius = 20;

		this.index = index;
		this.manager = manager;

		this.layer = layer;
		this.position = position;
		this.marker = marker;

		// create a sensor around the marker to test for touch presence
		this.sensor = new Ellipse({
			x: this.position[0],
			y: this.position[1] - this.radius / 3,
			radiusX: this.radius / 2,
			radiusY: this.radius,
		});

		// create a sensor around the marker to test for icon presence during the race
		this.raceSensor = new Circle({
			x: this.position[0],
			y: this.position[1],
			radius: this.raceRadius,
			stroke: `#ffff00`,
		});
		this.raceHit = false;

		// if debug, draw a border
		if (getURLParameterByName(`debug`) !== null) {
			this.sensor.stroke(`#00ffff`);
			this.layer.add(this.raceSensor);
		}

		this.layer.add(this.sensor);
		this.layer.draw();
	}

	touch() {
		if (
			this.marker.marker
				.image()
				.src.split(`/`)
				.pop() === this.marker.unlitImageSrc.split(`/`).pop()
		) {
			// if the image is not already illuminated
			// light up the marker
			this.marker.illuminate();

			// push the index to the order
			this.manager.order.push(this.index);
		}
	}
}
