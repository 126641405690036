// ========= REACT =========
import React from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	padding: calc(var(--margin) * 1) calc(var(--margin) * 5)
		calc(var(--margin) * 0.25) calc(var(--margin) * 5);

	background: ${COLORS.grey[`100`]};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const Subtitle = styled.div`
	color: ${COLORS.defaults.yellow};
	font-size: calc(var(--u) * 4);
	font-style: italic;
	transform: rotate(-5deg);
`;

const Title = styled.div`
	width: calc(var(--u) * 120);

	color: ${COLORS.defaults.yellow};
	text-transform: uppercase;
	font-family: acumin-pro, sans-serif;
	font-weight: 900;
	font-style: italic;
	line-height: calc(var(--u) * 17);
	font-size: calc(var(--u) * 20);

	transform: rotate(-5deg);
`;

const Billboard = styled(Img)`
	width: 450px;
	flex-shrink: 0;
`;

const Description = styled.div`
	color: ${COLORS.grey[`600`]};
	font-size: 1em;
	padding: 0 var(--margin);

	.sentiment {
		font-weight: bolder;
	}
`;

const CTA = styled(Button)`
	&& {
		background: ${COLORS.grey[`50`]};

		border-radius: var(--radius);
		border: 2px solid ${COLORS.defaults.yellow};

		color: ${COLORS.defaults.yellow};
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 12);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 10px 20px ${COLORS.grey[`900`]}33);

		&:hover {
			background: ${COLORS.yellow[`100`]};
		}

		text-transform: unset;
	}
`;

// ========= MAIN =========
const Start = ({ manager, onButtonClick }) => {
	return (
		<Container>
			<Subtitle>{COPY[SCENES.START].subhead}</Subtitle>
			<Title>{COPY[SCENES.START].head}</Title>
			<Billboard
				fluid={findFluidGatsbyImage(
					manager.images,
					`billboard`
				)}
			/>
			<Description>{COPY[SCENES.START].body}</Description>
			<br />
			<Description className="sentiment">
				{COPY[SCENES.START].sentiment}
			</Description>
			<CTA onClick={onButtonClick}>
				{COPY[SCENES.START].button}
			</CTA>
		</Container>
	);
};

export default connect(null, (dispatch) => {
	return {
		onButtonClick: () => dispatch(ACTIONS.setScene(SCENES.CHOOSE)),
	};
})(Start);
