import SCENES from "../etc/scenes";
import BUSINESSES from "../etc/businesses";

export default {
	scene: SCENES.START,
	hasStartedPainting: false,
	isPainting: false,
	doneDrawing: false,
	business: BUSINESSES.NONE,
	player: {
		stars: 0,
		profit: 1000,
	},
	cloud: {
		stars: 0,
		profit: 1000,
	},
	win: true,
	highscore: true,
	id: `0`,
	tick: 999999,
};
