import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { findFluidGatsbyImage } from "../libs/utils";

const Icon = styled(Img)`
	width: calc(20 * var(--u));
	margin: 2em;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100vw;
	height: 100vh;

	background: #eeeeee;
	text-align: center;
`;

const Mobile = ({ images }) => {
	return (
		<div>
			<div>
				<Wrapper>
					<Icon
						fluid={findFluidGatsbyImage(images, `alert`)}
					/>
					<p>
						Mobile devices are currently not supported.
						<br />
						Please visit this site from a desktop or tablet.
					</p>
				</Wrapper>
			</div>
		</div>
	);
};

export default Mobile;
