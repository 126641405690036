// ========= SENTRY =========
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === `production`)
	Sentry.init({
		dsn: `https://946492736e9c4e3eafb8017eb985f7ff@sentry.io/1417946`,
	});

// ========= REACT =========
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Provider } from "react-redux";

// ========= COMPONENTS =========
import MapLoader from "../components/MapLoader";
import { getURLParameterByName } from "../libs/utils";
import Mobile from "../components/Mobile";

// ========= UTILITIES =========
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faStar as star,
	faClock as clock,
	faStarHalfAlt as halfStar,
	faSpinner as spinner,
	faTimes as times,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as outlineStar } from "@fortawesome/free-regular-svg-icons";
library.add([star, outlineStar, halfStar, clock, spinner, times]);
import "../app/utility";

import { isMobileOnly } from "react-device-detect";

// ========= TOKENS =========
import * as ACTIONS from "../state/actions";

// ========= STYLE =========
import "normalize.css";
import "../styles/main.css";
import "../styles/typography.css";
import "../styles/hideMapsUI.css";
import "../styles/sizing.css";

// ========= STATE =========
// create redux store
import State from "../state/state";
const state = new State();
state.create();

// ========= MAIN =========
const IndexPage = ({ data }) => {
	useEffect(() => {
		// DEBUG: set scene according to URL param
		const scene = getURLParameterByName(`scene`);
		if (scene !== null) {
			state.store.dispatch(ACTIONS.setScene(scene));
		}
	});

	return (
		<>
			{isMobileOnly && <Mobile images={data} />}
			{!isMobileOnly && (
				<Provider store={state.store} images={data}>
					<MapLoader store={state.store} images={data} />
				</Provider>
			)}
		</>
	);
};

// ========= GRAPHQL =========
export const query = graphql`
	query {
		allImageSharp {
			edges {
				node {
					fluid(quality: 90) {
						...GatsbyImageSharpFluid_withWebp_noBase64
						originalName
					}
				}
			}
		}
	}
`;

export default IndexPage;
