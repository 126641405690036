import Poisson from "poisson-disc-sampler";
import uniqBy from "lodash.uniqby";
import hagen from "hagen";
import styles from "../libs/map-style";

import {
	convertLatLngToPoints,
	convertSingleLatLngToPoint,
} from "../libs/canvasToMap";
import {
	convertToRange,
	arrayTo2D,
	getURLParameterByName,
	findSrc,
} from "../libs/utils";

import { API_KEY, NUM_MARKERS } from "../etc/config";

export default class Manager {
	constructor({ api, lat, lng, zoom, type, store, images }) {
		hagen.log(`MANAGER`, `Creating Manager...`);

		this.api = api;
		this.store = store;
		this.images = images;

		this.center = { lat, lng };
		this.zoom = zoom;

		this.type = type;

		this.points = [];

		this.order = [];
		this.firstHalfTime = 0;
		this.secondHalfTime = 0;
		this.optimizedSecondHalfTime = 0;
		this.cloudTime = 0;

		this.initMap();
	}

	// ========= MAP =========

	// create map
	initMap() {
		// create the map
		hagen.log(`MANAGER`, `Creating & drawing map via Google API`);
		this.map = new this.api.Map(document.querySelector(`#map`), {
			center: this.center,
			zoom: this.zoom,
			disableDefaultUI: true,
			gestureHandling: `none`,
			zoomControl: false,
			styles: styles.default,
		});

		// const trafficLayer = new this.api.TrafficLayer();
		// trafficLayer.setMap(this.map);

		// create the overlay (for converting between canvas and physical space)
		hagen.log(
			`MANAGER`,
			`Creating geo <-> pixel conversion overlay`
		);
		this.overlay = new this.api.OverlayView();
		this.overlay.setMap(this.map);
	}

	updateMap({ lat, lng, zoom }) {
		this.center = { lat, lng };
		this.zoom = zoom;

		this.map.setCenter(
			new this.api.LatLng(this.center.lat, this.center.lng)
		);
		this.map.setZoom(this.zoom);
	}

	// ========= WAYPOINTS =========

	// kick off the waypoint adding process
	createWaypoints() {
		hagen.log(`MANAGER`, `Getting map geo bounds`);

		// get the bounds of the map
		this.bounds = this.map.getBounds();
		if (this.bounds === undefined) {
			this.api.event.addListenerOnce(
				this.map,
				`bounds_changed`,
				() => {
					this.bounds = this.map.getBounds();
				}
			);
		}

		// add markers with artificial delay
		setTimeout(() => this.getPlaces(this.bounds), 500);
	}

	// get waypoint locations
	getPlaces(bounds, numPoints = NUM_MARKERS) {
		hagen.log(`MANAGER`, `Generating marker locations`);

		const { north, east, south, west } = bounds.toJSON();

		// create inset bounds to avoid screen edges and UI elements
		const inset = 0.1;
		const insetNorth = north - (north - south) * inset * 1.85;
		const insetEast = east - (east - west) * inset;
		const insetWest = west + (east - west) * inset;
		const insetSouth = south + (north - south) * inset * 3.5;

		// show the inset bounds if needed
		if (getURLParameterByName(`debug`) !== null) {
			const coords = [
				{ lat: insetNorth, lng: insetEast },
				{ lat: insetSouth, lng: insetEast },
				{ lat: insetSouth, lng: insetWest },
				{ lat: insetNorth, lng: insetWest },
			];

			// Construct the polygon.
			const shape = new this.api.Polygon({
				paths: coords,
				strokeColor: `#ff00ff`,
				strokeWeight: 2,
				fillColor: `#ff00ff`,
				fillOpacity: 0,
			});
			shape.setMap(this.map);
		}

		// create a random point distribution with minimum distances
		const sampler = Poisson(1, 1, 0.15);

		const points = [];
		for (let i = 0; i < numPoints; i++) {
			const point = sampler();
			const pt = {
				lat: convertToRange(
					point[0],
					0,
					1,
					insetSouth,
					insetNorth
				),
				lng: convertToRange(
					point[1],
					0,
					1,
					insetWest,
					insetEast
				),
			};
			const xy = convertSingleLatLngToPoint({
				manager: this,
				point: new this.api.LatLng(pt),
			});

			pt.x = xy.x;
			pt.y = xy.y;

			points.push(pt);
		}

		// snap the points to the closest road
		hagen.log(
			`MANAGER`,
			`Requesting nearest roads for randomly placed markers`
		);

		this.fetchSnaps(points, (res) => {
			hagen.log(`MANAGER`, `Snapping`);
			if (res.length < NUM_MARKERS) {
				hagen.log(`SNAP`, `Not enough markers; trying again`);
				this.getPlaces(bounds);
			} else {
				this.points = res.map((point) => {
					return {
						lat: point.location.latitude,
						lng: point.location.longitude,
						x: points[point.originalIndex].x,
						y: points[point.originalIndex].y,
					};
				});

				// add the markers to the map
				this.addMarkers();
			}
		});
	}

	// add waypoint markers to map
	addMarkers() {
		hagen.log(`MANAGER`, `Placing markers on map`);

		// create LatLngs
		const latLngs = this.points.map(
			(point) => new this.api.LatLng(point)
		);

		// convert them to pixels
		const pixels = convertLatLngToPoints({
			manager: this,
			latLngs,
		});
		const pixels2D = arrayTo2D(pixels);

		for (let i = 0; i < this.points.length; i++) {
			// over time
			setTimeout(() => {
				// create the marker
				const options = {
					position: pixels2D[i],
					index: i,
					unlitImageSrc: findSrc(
						this.images,
						`waypoint_unlit`
					),
					litImageSrc: findSrc(this.images, `waypoint_lit`),
				};

				if (i === 0) {
					options.unlitImageSrc = findSrc(
						this.images,
						this.store.getState().business
							.startWaypointUnlit
					);
					options.litImageSrc = findSrc(
						this.images,
						this.store.getState().business.startWaypointLit
					);

					options.width = (2400 * 125) / 1600;
					options.height = (2400 * 125) / 1600;
				}
				if (i === NUM_MARKERS - 1) {
					options.unlitImageSrc = findSrc(
						this.images,
						this.store.getState().business.endWaypointUnlit
					);
					options.litImageSrc = findSrc(
						this.images,
						this.store.getState().business.endWaypointLit
					);
				}

				this.canvas.addMarker(options);
			}, i * 50);
		}
	}

	// fetch the road snaps from the google api
	async fetchSnaps(points, cb = (res) => console.log(res)) {
		const pairs = points.map(
			(point) => `${point.lat},${point.lng}`
		);

		const response = await fetch(
			`https://roads.googleapis.com/v1/nearestRoads?key=${API_KEY}&points=${pairs.join(
				`|`
			)}`
		);
		const data = await response.json();

		// remove bidirectional duplicates
		cb(uniqBy(data.snappedPoints, `originalIndex`));
	}

	// ========= DIRECTIONS =========
	// get and draw cloud directions
	async getDirections({
		order = [],
		shouldOptimize = true,
		shouldDraw = true,
		player = `CLOUD`,
	}) {
		hagen.log(`MANAGER`, `Getting directions via Google API`);

		this.directionsService = new this.api.DirectionsService();

		let waypoints = [];

		if (order.length === 0) {
			// if the order doesn't matter
			waypoints = this.points.map((point) => ({
				location:
					point.geometry === undefined
						? point
						: point.geometry.location,
				stopover: true,
			}));
		} else {
			// if the order does matter
			waypoints = order.map((index) => {
				return {
					location:
						this.points[index].geometry === undefined
							? this.points[index]
							: this.points[index].geometry.location,
					stopover: true,
				};
			});
		}

		console.log(waypoints);
		const origin = waypoints.shift().location;
		const destination = waypoints.pop().location;

		const plot = () =>
			new Promise((resolve, reject) =>
				this.directionsService.route(
					{
						origin,
						destination,
						travelMode: this.store.getState().business.mode,
						waypoints,
						optimizeWaypoints: shouldOptimize,
						drivingOptions: {
							departureTime: new Date(),
						},
					},
					async (response, status) => {
						if (status === `OK`) {
							const latLngs = [];

							let timer = 0;
							response.routes[0].legs.forEach((leg) => {
								timer += leg.duration.value;
								leg.steps.forEach((step) => {
									step.path.forEach((point) => {
										latLngs.push(point);
									});
								});
							});

							switch (player) {
								case `CLOUD`:
									this.cloudTime = timer;
									break;
								case `FIRST`:
									this.firstHalfTime = timer;
									break;
								case `SECOND`:
									this.secondHalfTime = timer;
									break;
								case `OPTIMIZED`:
									this.optimizedSecondHalfTime = timer;
									break;
								default:
									break;
							}

							const pixels = convertLatLngToPoints({
								manager: this,
								latLngs,
							});

							if (!shouldDraw) {
								resolve(pixels);
								return;
							}

							await this.canvas.drawDirections(pixels);

							resolve(pixels);
						} else {
							reject();
						}
					}
				)
			);

		const pix = await plot();
		hagen.log(`MANAGER`, `Directions plotted`);
		return pix;
	}
}
