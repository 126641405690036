import TYPES from "./types";

export default function mapsApp(state = {}, action) {
	switch (action.type) {
		case TYPES.SET_SCENE:
			return { ...state, scene: action.scene };

		case TYPES.SET_HAS_STARTED_PAINTING:
			return {
				...state,
				hasStartedPainting: action.hasStartedPainting,
			};

		case TYPES.SET_IS_PAINTING:
			return { ...state, isPainting: action.isPainting };

		case TYPES.SET_DONE_DRAWING:
			return { ...state, doneDrawing: action.done };

		case TYPES.SET_BUSINESS:
			return { ...state, business: action.business };

		case TYPES.SET_PLAYER:
			return {
				...state,
				player: {
					...state.player,
					stars: action.stars,
					profit: action.profit,
				},
			};

		case TYPES.SET_PLAYER_TRAFFIC:
			return {
				...state,
				player: {
					...state.player,
					traffic: action.traffic,
				},
			};

		case TYPES.SET_CLOUD:
			return {
				...state,
				cloud: {
					...state.cloud,
					stars: action.stars,
					profit: action.profit,
				},
			};

		case TYPES.SET_WIN:
			return {
				...state,
				win: action.win,
			};

		case TYPES.SET_HIGHSCORE:
			return {
				...state,
				highscore: action.highscore,
			};

		case TYPES.SET_ID:
			return {
				...state,
				id: action.id,
			};

		case TYPES.BUMP_PLAYER:
			return {
				...state,
				player: {
					...state.player,
					profit: state.player.profit + 5,
				},
			};

		case TYPES.SET_ICON_TICK:
			return { ...state, tick: action.tick };
		default:
			return state;
	}
}
