export default {
	SET_SCENE: `SET_SCENE`,
	SET_HAS_STARTED_PAINTING: `SET_HAS_STARTED_PAINTING`,
	SET_IS_PAINTING: `SET_IS_PAINTING`,
	SET_DONE_DRAWING: `SET_DONE_DRAWING`,
	SET_BUSINESS: `SET_BUSINESS`,
	SET_PLAYER: `SET_PLAYER`,
	SET_PLAYER_TRAFFIC: `SET_PLAYER_TRAFFIC`,
	SET_CLOUD: `SET_CLOUD`,
	SET_WIN: `SET_WIN`,
	SET_HIGHSCORE: `SET_HIGHSCORE`,
	SET_ID: `SET_ID`,
	BUMP_PLAYER: `BUMP_PLAYER`,
	SET_ICON_TICK: `SET_ICON_TICK`,
};
