import React from "react";
import styled from "styled-components";

import COLORS from "@deeplocal/colors/google";

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Box = styled.div`
	width: calc(160 * var(--u));
	margin: var(--margin);

	filter: drop-shadow(0px 10px 20px ${COLORS.grey[`900`]}33);
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	background: ${COLORS.defaults.yellow};
	color: white;

	border-radius: var(--radius) var(--radius) 0px 0px;

	padding: var(--margin);
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;

	padding: calc(2 * var(--u));
	border-radius: 0px 0px var(--radius) var(--radius);

	background: white;
	color: ${COLORS.grey[`600`]};
`;

const PopUp = ({ top, bottom }) => {
	return (
		<Container>
			<Box>
				<Top>{top}</Top>
				<Bottom>{bottom}</Bottom>
			</Box>
		</Container>
	);
};

export default PopUp;
