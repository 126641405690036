// ========= REACT =========
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRefreshAfterIdle } from "../hooks";

// ========= COMPONENTS =========
import styled from "styled-components";
import PopUp from "../PopUp";
import Button from "@material-ui/core/Button";

// ========= UTILITIES =========
import { ordinalSuffix } from "../../libs/utils";
import { getScores } from "../../app/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuid from "uuid/v4";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: var(--margin);
`;

const DoneButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};

		border-radius: var(--radius);
		/* border: 2px solid ${COLORS.defaults.yellow}; */

		color: white;
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		width: calc(var(--u) * 80);

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}
		
		text-transform: unset;
	}
`;

const ScoreBoard = styled.div`
	width: calc(100% + calc(calc(var(--u) * 4)));
	height: calc(var(--u) * 70);

	margin: calc(var(--u) * -2);
	padding: 0;
	border-radius: 0 0 var(--radius) var(--radius);

	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: calc(var(--u) * 6);
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 10px 10px transparent;
		border: solid calc(var(--u) * 2) transparent;
		border-radius: 0 0 var(--radius) 0;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px ${COLORS.defaults.yellow};
		border-radius: var(--radius);
		border: solid calc(var(--u) * 2) transparent;
	}
`;

const PlayerContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;

	width: 100%;
	height: 20%;

	color: ${(props) => {
		if (props.isPlayer) {
			return `white`;
		}
		switch (props.order) {
			case 1:
				return COLORS.defaults.blue;
			case 2:
				return COLORS.defaults.yellow;
			case 3:
				return COLORS.defaults.green;
			default:
				return COLORS.defaults.grey;
		}
	}};

	background: ${(props) => {
		if (props.isPlayer) {
			return COLORS.defaults.red;
		}

		return props.order % 2 === 0
			? COLORS.grey[`100`]
			: COLORS.grey[`50`];
	}};
`;

const Ordinal = styled.div`

	align-self: center;
	
	width: 12%;
	height: calc(var(--u) * 12);
	line-height: calc(var(--u) * 12);

	font-size: 2.75em;
	font-weight: bold;

	border-radius: calc(var(--radius) / 6);
	
	${(props) =>
		props.order <= 3 &&
		`filter: drop-shadow(0px 5px 5px \${COLORS.grey[\`900\`]}22);`}

	${(props) => {
		switch (props.order) {
			case 1:
			case 3:
				return `transform: rotate(-5deg);`;
			case 2:
				return `transform: rotate(5deg);`;
			default:
				return ``;
		}
	}}

	color: ${(props) => (props.order <= 3 ? `white` : `inherit`)};
	background: ${(props) => {
		switch (props.order) {
			case 1:
				return COLORS.defaults.blue;
			case 2:
				return COLORS.defaults.yellow;
			case 3:
				return COLORS.defaults.green;
			default:
				return `inherit`;
		}
	}};
`;

const Name = styled.div`
	width: 20%;

	font-size: 2em;
	font-weight: bold;

	span {
		display: inline-block;
		width: 1.2em;
		text-align: center;
	}
`;

const PlayerScore = styled.div`
	width: 20%;

	font-size: 2em;
	font-weight: bold;

	span {
		display: inline-block;
		width: 0.65em;
		text-align: center;
	}
`;

const Player = ({ order, name, score, isPlayer }) => {
	return (
		<PlayerContainer order={order} isPlayer={isPlayer}>
			<Ordinal order={order}>{ordinalSuffix(order)}</Ordinal>
			<Name>{name}</Name>
			<PlayerScore>
				{/* if the score is a number, make it monospaced */}
				{/* if the score is a loading spinner, display it */}
				{typeof score === `number`
					? `${score}`.split(``).map((letter, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<span key={`${letter}-${i}`}>{letter}</span>
					))
					: score}
			</PlayerScore>
		</PlayerContainer>
	);
};

// ========= MAIN =========
const Score = ({ state, id }) => {
	// useRefreshAfterIdle(); // restart app after time

	const [disableButton, setDisableButton] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setDisableButton(false);
		}, 1000);
	}, []);

	// get scores
	const [scores, setScores] = useState([]);
	useEffect(() => {
		const NUM_SCORES = 10;

		const spinner = {
			name: (
				<FontAwesomeIcon icon="spinner" className="fa-pulse" />
			),
			score: (
				<FontAwesomeIcon icon="spinner" className="fa-pulse" />
			),
		};

		// get real scores from firebase, async
		const fetchData = async () => {
			const result = await getScores(NUM_SCORES);

			if (result.length < NUM_SCORES) {
				for (let i = result.length; i < NUM_SCORES; i++) {
					result.push({ ...spinner, id: `SPINNER-${i}` });
				}
			}
			setScores(result);
		};
		fetchData();

		// set temp scores while real scores are loading
		if (scores.length === 0) {
			const tempScores = [];
			for (let i = 0; i < NUM_SCORES; i++) {
				tempScores.push({ ...spinner, id: `SPINNER-${i}` });
			}
			setScores(scores);
		}
	}, []);

	return (
		<Container>
			<PopUp
				top={<h2>High Scores</h2>}
				bottom={
					<ScoreBoard>
						{scores.map((player, i) =>
							player === undefined ? (
								<Player
									name=""
									score=""
									order={i + 1}
									// eslint-disable-next-line react/no-array-index-key
									key={uuid()}
									isPlayer={false}
								/>
							) : (
									<Player
										name={player.name}
										score={player.score}
										order={i + 1}
										key={player.id}
										isPlayer={player.id === id}
									/>
								)
						)}
					</ScoreBoard>
				}
			/>
			<DoneButton
				disabled={disableButton}
				onClick={async () => {
					if (!disableButton) {
						window.location.href = `/`;
					}
				}}
			>
				{COPY[SCENES.SCORE].button}
			</DoneButton>
		</Container>
	);
};

export default connect((state) => {
	return {
		state,
		id: state.id,
	};
})(Score);
