// ========= REACT =========
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import { PlayerScorePanel, CloudScorePanel } from "../ScorePanel";

// ========= UTILITIES =========
import hagen from "hagen";
import {
	findFluidGatsbyImage,
	getURLParameterByName,
} from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import SCENES from "../../etc/scenes";
import COLORS from "@deeplocal/colors/google";
import { NUM_MARKERS } from "../../etc/config";
import COPY from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	position: absolute;
	top: 0;
	left: 0;

	width: calc(100% - calc(var(--margin) * 2));
	height: calc(100% - calc(var(--margin) * 2));

	padding: var(--margin);
`;

const Rerouter = styled.div`
	width: calc(var(--u) * 135);
	background: white;
	padding: calc(var(--margin) / 2);
	margin: 0 var(--margin);
	border-radius: var(--radius);
	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

	border: 10px solid transparent;

	display: flex;
	justify-content: space-evenly;
	align-items: center;

	${(properties) =>
		properties.blink
			? `animation: blink 0.75s 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
		3;`
			: ``}

	@keyframes blink {
		to {
			border: 10px solid ${COLORS.defaults.red};
		}
	}
`;

const Icon = styled(Img)`
	width: 13%;
`;

const Labels = styled.div`
	width: 35%;
`;
const Label = styled.h5`
	font-size: 1em;
	color: ${COLORS.defaults.grey};
`;

const ProgressBar = styled.div.attrs((properties) => ({
	style: {
		width: `${properties.percent}% `,
	},
}))`
	width: 20%;
	height: calc(var(--u) * 2);
	background: ${COLORS.grey[`300`]};
	border-radius: var(--radius);

	div {
		/* display: block; */
		height: 100%;
		background: ${COLORS.defaults.blue};
		border-radius: var(--radius);
	}
`;

const Buttons = styled.div`
	width: 15%;

	border-radius: calc(var(--radius) / 2);
	font-size: 1em;

	padding: 0 calc(var(--u) * 4);

	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);
	text-transform: unset;
`;
const YesButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.blue};
		margin: calc(var(--u) * 1) 0;

		color: white;
		width: 100%;

		&: hover {
			background: ${COLORS.blue[`600`]};
		}
	}
`;
const NoButton = styled(Button)`
	&& {
		border-radius: calc(var(--radius) / 2);
		margin: calc(var(--u) * 1) 0;

		font-weight: normal;
		color: ${COLORS.grey[`600`]};

		width: 100%;

		.fa-times {
			margin-right: 1em;
		}
	}
`;

// ========= MAIN =========

const Race = ({ manager, business, finishRace, tick }) => {
	const SHOW_EVERY = 4;
	const TICK_TRIGGER = 400;
	const TICK_BOTTOM = 25;

	const [done, setDone] = useState(0);
	const [displayAsk, setDisplayAsk] = useState(false);
	const [asked, setAsked] = useState(false);
	const [displayFollowup, setDisplayFollowup] = useState(false);
	const [shouldReroute, setShouldReroute] = useState(false);
	const [askPercentage, setAskPercentage] = useState(0);
	const [secondHalf, setSecondHalf] = useState(false);
	const [alert, setAlert] = useState(``);
	const [alertPositioned, setAlertPositioned] = useState(false);
	const [shouldAsk, setShouldAsk] = useState(false);

	// ask for reroute
	useEffect(() => {
		if (!asked) {
			// determine if toast should be shown
			if (shouldAsk && tick <= TICK_TRIGGER) {
				setDisplayAsk(true);

				// position the alert icon
				if (!alertPositioned) {
					manager.canvas.positionAlertIcon(alert.icon);
					manager.canvas.changeSecondHalfColor();
					setAlertPositioned(true);
				}

				// animate the progress bar
				// FIXME: tick isn't working great
				setAskPercentage((tick / TICK_TRIGGER) * 100);

				// remove the toast when complete
				if (tick <= TICK_BOTTOM) {
					setDisplayAsk(false);
					setAsked(true);
				}
			}
		}
	}, [tick, alertPositioned, alert, shouldAsk]);

	// travel along second-half paths
	useEffect(() => {
		if (secondHalf) {
			if (!shouldReroute) {
				(async () => {
					const speed = 1; // normal speed = 1.0; slower > 1, faster < 1

					// travel along the unoptimized path
					await manager.canvas.playerIcon.travel({
						path: manager.canvas.secondHalfRouteLine,
						waypointPercentHit:
							manager.canvas.markerLayer.markersHit /
							NUM_MARKERS,
						speed: manager.secondHalfTime * speed,
					});

					// when done increment the flag
					setDone((d) => d + 1);
				})();
			} else {
				(async () => {
					const speed = 1.01; // normal speed = 1.0; slower > 1, faster < 1

					// travel along the optimized path
					await manager.canvas.playerIcon.travel({
						path:
							manager.canvas.secondHalfOptimizedRouteLine,
						waypointPercentHit:
							manager.canvas.markerLayer.markersHit /
							NUM_MARKERS,
						speed: manager.optimizedSecondHalfTime * speed,
						// speed: manager.secondHalfTime * speed,
					});

					// when done increment the flag
					setDone((d) => d + 1);
				})();
			}
		}
	}, [secondHalf, shouldReroute]);

	// start race
	useEffect(() => {
		(async () => {
			// get directions for cloud icon and draw them
			await manager.getDirections({});

			manager.canvas.clearRouteLineForGhost();

			const speed = 1; // normal speed = 1.0; slower > 1, faster < 1

			// travel both icons (player first half, and cloud)
			await Promise.all([
				new Promise(async (finishHalf) => {
					await manager.canvas.playerIcon.travel({
						path: manager.canvas.firstHalfRouteLine,
						speed: manager.firstHalfTime * speed,
					});

					// when done travelling first half, start the second half
					setSecondHalf(true);
					finishHalf();
				}),

				manager.canvas.cloudIcon.travel({
					path: manager.canvas.directionsLine,
					speed: manager.cloudTime,
				}),
			]);

			// when both the cloud and the first half of the race are done, increment this flag
			setDone((d) => d + 1);
		})();
	}, []);

	// end race
	useEffect(() => {
		// if all three parts of the race (cloud, first half, second half) are done
		if (done === 2) {
			hagen.log(`GUI`, `RACE OVER`);
			setTimeout(() => {
				// move on to the next scene
				finishRace();
			}, 1000);
		}
	}, [done]);

	// set alert text on first render, based on random selection
	// set whether reroute should ask
	useEffect(() => {
		const value = Math.floor(
			Math.random() * COPY[SCENES.RACE].alerts.length
		);
		setAlert({ ...COPY[SCENES.RACE].alerts[value] });

		setShouldAsk(
			getURLParameterByName(`debug`) === null
				? Math.floor(Math.random() * SHOW_EVERY) < 3
				: true
		);
	}, []);

	// ========= RETURN =========
	return (
		<Container>
			<PlayerScorePanel
				color={business.guest.color}
				icon={findFluidGatsbyImage(
					manager.images,
					business.guest.playerIcon
				)}
				label={business.name}
			/>

			{displayAsk && (
				<Rerouter blink>
					<Icon
						fluid={findFluidGatsbyImage(
							manager.images,
							`alert`
						)}
					/>
					<Labels>
						<Label>{alert.copy}</Label>
						<Label>{COPY[SCENES.RACE].reroute}</Label>
					</Labels>
					<ProgressBar>
						<div style={{ width: `${askPercentage}% ` }} />
					</ProgressBar>
					<Buttons>
						<YesButton
							variant="contained"
							onClick={async () => {
								setDisplayAsk(false);
								setAsked(true);
								setDisplayFollowup(true);
								setShouldReroute(true);

								// undraw the old second half
								manager.canvas.handDraw({
									points: manager.canvas.secondHalfRouteLine.points(),
									line:
										manager.canvas
											.secondHalfRouteLine,
									layer: manager.canvas.routeLayer,
									reverse: true,
								});

								// while simultaneously drawing the new second half
								await manager.canvas.handDraw({
									points: manager.canvas.secondHalfOptimizedRouteLine.points(),
									line:
										manager.canvas
											.secondHalfOptimizedRouteLine,
									layer: manager.canvas.routeLayer,
								});
							}}
						>
							{COPY[SCENES.RACE].confirm.button}
						</YesButton>
						<NoButton
							onClick={() => {
								setDisplayAsk(false);
								setAsked(true);
								setDisplayFollowup(true);
								setShouldReroute(false);
							}}
						>
							<FontAwesomeIcon icon="times" />
							{COPY[SCENES.RACE].dismiss.button}
						</NoButton>
					</Buttons>
				</Rerouter>
			)}

			{displayFollowup && (
				<Rerouter blink={false}>
					<div>
						{shouldReroute ? (
							<p style={{ textAlign: `center` }}>
								<span
									style={{
										color: COLORS.defaults.green,
										marginRight: `0.5em`,
									}}
								>
									{COPY[SCENES.RACE].confirm.head}
								</span>
								<span
									style={{
										color: COLORS.defaults.grey,
									}}
								>
									{COPY[SCENES.RACE].confirm.body}
								</span>
							</p>
						) : (
								<p style={{ textAlign: `center` }}>
									<span
										style={{
											color: COLORS.defaults.red,
											marginRight: `0.5em`,
										}}
									>
										{COPY[SCENES.RACE].dismiss.head}
									</span>
									<span
										style={{
											color: COLORS.defaults.grey,
										}}
									>
										{COPY[SCENES.RACE].dismiss.body}
									</span>
								</p>
							)}
					</div>
				</Rerouter>
			)}

			<CloudScorePanel
				color={business.cloud.color}
				icon={findFluidGatsbyImage(
					manager.images,
					business.cloud.playerIcon
				)}
				label="Google Maps Platform"
			/>
		</Container>
	);
};

export default connect(
	(state) => {
		return {
			state,
			business: state.business,
			player: state.player,
			cloud: state.cloud,
			tick: state.tick,
		};
	},
	(dispatch) => {
		return {
			finishRace: () => {
				dispatch(ACTIONS.setScene(SCENES.COMPARE));
			},
		};
	}
)(Race);
