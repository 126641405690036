import { createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import initialState from "./initialState";

import REDUCERS from "./reducers";

export default class State {
	create() {
		this.store = createStore(
			REDUCERS,
			initialState,
			devToolsEnhancer()
		);
	}
}
