// ========= REACT =========
import React, { useState } from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import PopUp from "../PopUp";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import BUSINESSES from "../../etc/businesses";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY from "../../etc/copy";
import STYLES from "../../libs/map-style";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Businesses = styled.div`
	width: 100%;

	display: flex;
	justify-content: space-around;
`;

const Business = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: var(--radius);
	border: 4px solid
		${(props) =>
		props.state === props.type
			? COLORS.defaults.yellow
			: `white`};

	&:hover {
		border: 4px solid ${COLORS.defaults.yellow};
	}

	&:active {
		background: ${COLORS.yellow[`100`]};
	}
`;

const IconHolder = styled.div`
	width: calc(var(--u) * 50);
	height: calc(var(--u) * 50);
	overflow: hidden;
`;

const Icon = styled(Img)`
	position: absolute;
	left: calc(calc(calc(var(--u) * 50) - calc(var(--u) * 60)) / 2);
	width: calc(var(--u) * 60);
`;

const ChooseButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};

		border-radius: var(--radius);
		/* border: 2px solid ${COLORS.defaults.yellow}; */

		color: white;
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		width: calc(var(--u) * 50);

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}
		
		text-transform: unset;
	}
`;

// ========= MAIN =========
const Choose = ({
	manager,
	business,
	onButtonClick,
	onBusinessClick,
}) => {
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const lines = [
		manager.canvas.touchLine,
		manager.canvas.routeLine,
		manager.canvas.firstHalfRouteLine,
		manager.canvas.secondHalfRouteLine,
		manager.canvas.secondHalfOptimizedRouteLine,
	];

	return (
		<Container>
			<PopUp
				top={
					<>
						<h3>{COPY[SCENES.CHOOSE].head}</h3>
						<h5>{COPY[SCENES.CHOOSE].subhead}</h5>
					</>
				}
				bottom={
					<>
						<Businesses>
							<Business
								state={business.state}
								type="TRUCK"
								onClick={() => {
									// set the state
									onBusinessClick(BUSINESSES.TRUCK);

									// enable the button
									setButtonDisabled(false);

									// reposition the map
									manager.updateMap(
										BUSINESSES.TRUCK.map
									);

									// restyle the map
									manager.map.setOptions({
										styles: STYLES.TRUCK,
									});

									// change the canvas line colors
									manager.canvas.setLinesColor({
										lines,
										color:
											BUSINESSES.TRUCK.guest
												.color,
									});
									manager.canvas.setLinesColor({
										lines: [
											manager.canvas
												.secondHalfOptimizedRouteLine,
										],
										color:
											BUSINESSES.TRUCK.guest
												.altColor,
									});

									// create the canvas icons
									manager.canvas.createIcons();
								}}
							>
								<IconHolder>
									<Icon
										fluid={findFluidGatsbyImage(
											manager.images,
											`player_truck_downright`
										)}
									/>
								</IconHolder>
								<p>{BUSINESSES.TRUCK.name}</p>
							</Business>

							<Business
								state={business.state}
								type="BIKE"
								onClick={() => {
									// set the state
									onBusinessClick(BUSINESSES.BIKE);

									// enable the button
									setButtonDisabled(false);

									// reposition the map
									manager.updateMap(
										BUSINESSES.BIKE.map
									);

									// restyle the map
									manager.map.setOptions({
										styles: STYLES.BIKE,
									});

									// change the canvas line colors
									manager.canvas.setLinesColor({
										lines,
										color:
											BUSINESSES.BIKE.guest.color,
									});
									manager.canvas.setLinesColor({
										lines: [
											manager.canvas
												.secondHalfOptimizedRouteLine,
										],
										color:
											BUSINESSES.BIKE.guest
												.altColor,
									});

									// create the canvas icons
									manager.canvas.createIcons();
								}}
							>
								<IconHolder>
									<Icon
										fluid={findFluidGatsbyImage(
											manager.images,
											`player_bike_downright`
										)}
									/>
								</IconHolder>
								<p>{BUSINESSES.BIKE.name}</p>
							</Business>

							<Business
								state={business.state}
								type="WALK"
								onClick={() => {
									// set the state
									onBusinessClick(BUSINESSES.WALK);

									// enable the button
									setButtonDisabled(false);

									// reposition the map
									manager.updateMap(
										BUSINESSES.WALK.map
									);

									// restyle the map
									manager.map.setOptions({
										styles: STYLES.WALK,
									});

									// change the canvas line colors
									manager.canvas.setLinesColor({
										lines,
										color:
											BUSINESSES.WALK.guest.color,
									});
									manager.canvas.setLinesColor({
										lines: [
											manager.canvas
												.secondHalfOptimizedRouteLine,
										],
										color:
											BUSINESSES.WALK.guest
												.altColor,
									});

									// create the canvas icons
									manager.canvas.createIcons();
								}}
							>
								<IconHolder>
									<Icon
										fluid={findFluidGatsbyImage(
											manager.images,
											`player_walk_downright`
										)}
									/>
								</IconHolder>
								<p>{BUSINESSES.WALK.name}</p>
							</Business>
						</Businesses>
						<ChooseButton
							onClick={onButtonClick}
							disabled={buttonDisabled}
						>
							{COPY[SCENES.CHOOSE].button}
						</ChooseButton>
					</>
				}
			/>
		</Container>
	);
};

export default connect(
	(state) => {
		return { business: state.business };
	},
	(dispatch) => {
		return {
			onBusinessClick: (business) =>
				dispatch(ACTIONS.setBusiness(business)),
			onButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.TUTORIAL)),
		};
	}
)(Choose);
