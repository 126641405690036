// ========= REACT =========
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Img from "gatsby-image";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY from "../../etc/copy";
import { TIMER_LENGTH } from "../../etc/config";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	pointer-events: ${(props) => (props.canDraw ? `none` : `auto`)};
`;

const NextButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};
		border-radius: var(--radius);

		color: ${COLORS.grey[`50`]};
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		margin: calc(var(--u) * 4);

		height: calc(var(--u) * 10);
		width: calc(var(--u) * 50);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}

		pointer-events: auto;
		text-transform: unset;
	}
`;

const Instructions = styled.div`
	display: ${(props) => (props.showInstructions ? `flex` : `none`)};

	width: calc(var(--u) * 120);
	border-radius: var(--radius);
	filter: drop-shadow(0px 10px 20px ${COLORS.grey[`900`]}33);

	background: white;
	color: ${COLORS.defaults.grey};
	h5 {
		color: ${COLORS.defaults.yellow};
	}
`;

const Little = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	padding: calc(var(--margin) / 2);

	width: 20%;
	color: ${COLORS.defaults.yellow};
`;

const Big = styled.div`
	padding: calc(var(--margin) / 2);
	width: 80%;
	align-self: center;
	text-align: left;
`;

const Icon = styled(Img)`
	width: calc(var(--u) * 20);
`;

const Timer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	font-size: 2.5em;

	border-radius: calc(var(--radius) / 2);

	position: absolute;
	top: 0;
	left: 0;
	background: ${(props) =>
		props.timer <= 5 ? COLORS.defaults.red : COLORS.defaults.yellow};
	color: white;

	width: calc(var(--u) * 25);

	padding: var(--u) calc(var(--u) * 6) var(--u) calc(var(--u) * 3);
	margin: calc(var(--margin) / 2);

	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

	animation: ${(props) =>
		props.timer <= 3
			? `shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;`
			: ``};
	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 1px, 0);
		}

		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}

		30%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}

	span {
		&#time {
			text-align: center;
			width: calc(var(--u) * 6);
		}

		&#separator {
			text-align: right;
			width: calc(var(--u) * 4);
		}
	}
`;

const TimeUp = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	font-size: 4em;
	font-weight: bold;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	pointer-events: auto;
	user-select: none;

	color: ${COLORS.defaults.yellow};

	h1 {
		background-color: white;
		position: absolute;
		border-radius: var(--radius);
		padding: var(--margin);
	}

	filter: drop - shadow(0px 10px 20px ${COLORS.grey[`900`]}33);
`;

// ========= MAIN =========
const Draw = ({
	manager,
	doneDrawing,
	onButtonClick,
	hasStartedPainting,
}) => {
	const [timer, setTimer] = useState(TIMER_LENGTH);
	const [timeUp, setTimeUp] = useState(false);
	const [canDraw, setCanDraw] = useState(false);

	// create waypoints
	useEffect(() => {
		manager.createWaypoints();

		// block drawing until pins are dropped
		setTimeout(() => {
			setCanDraw(true);
		}, 1500);
	}, []);

	// run timer
	useEffect(() => {
		let paintTimeout;
		if (hasStartedPainting && timer > 0)
			paintTimeout = setTimeout(() => {
				setTimer((t) => t - 1);
			}, 1000);

		if (timer === 0) {
			// fake mouse button release
			const evt = document.createEvent(`MouseEvents`);
			evt.initEvent(`mouseup`, true, true);
			manager.canvas.stage.dispatchEvent(evt);

			setTimeUp(true);
		}

		return () => {
			clearTimeout(paintTimeout);
		};
	}, [timer, hasStartedPainting]);

	return (
		<Container canDraw={canDraw}>
			{timeUp && (
				<TimeUp>
					<h1>Time&apos;s Up!</h1>
				</TimeUp>
			)}
			<Timer className={timer <= 3 ? `shake` : ``} timer={timer}>
				<FontAwesomeIcon icon="clock" />
				<span id="separator">:</span>
				<span id="time" className={timer <= 3 ? `shake` : ``}>
					{timer}
				</span>
			</Timer>
			<Instructions showInstructions={!hasStartedPainting}>
				<Little>
					<Icon
						fluid={findFluidGatsbyImage(
							manager.images,
							`touch`
						)}
					/>
				</Little>
				<Big>
					<h5>{COPY[SCENES.DRAW].head}</h5>
					<p>{COPY[SCENES.DRAW].body}</p>
				</Big>
			</Instructions>
			<NextButton
				disabled={!doneDrawing}
				onClick={() => {
					if (doneDrawing) {
						manager.canvas.setHiddenRouteLines();
						onButtonClick();
					}
				}}
			>
				{COPY[SCENES.DRAW].button}
			</NextButton>
		</Container>
	);
};

export default connect(
	(state) => {
		return {
			doneDrawing: state.doneDrawing,
			hasStartedPainting: state.hasStartedPainting,
		};
	},
	(dispatch) => {
		return {
			onButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.READY)),
		};
	}
)(Draw);
