import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Head({ title, children }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
					}
				}
			}
		`
	);

	return (
		<Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
			<html lang="en" />
			<title>{title}</title>
			{children}
		</Helmet>
	);
}

export default Head;
