export default {
	default: [
		{
			elementType: `labels`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `administrative.land_parcel`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.stroke`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
		{
			featureType: `poi`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `road`,
			elementType: `geometry`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
	],
	TRUCK: [
		{
			elementType: `labels`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `administrative.land_parcel`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.stroke`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.fill`,
			stylers: [
				{
					color: `#fce8e6`,
				},
			],
		},
		{
			featureType: `poi`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `road`,
			elementType: `geometry`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
	],
	BIKE: [
		{
			elementType: `labels`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `administrative.land_parcel`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.stroke`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.fill`,
			stylers: [
				{
					color: `#e6f4ea`,
				},
			],
		},
		{
			featureType: `poi`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `road`,
			elementType: `geometry`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
	],
	WALK: [
		{
			elementType: `labels`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `administrative.land_parcel`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.stroke`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
		{
			featureType: `landscape.man_made`,
			elementType: `geometry.fill`,
			stylers: [
				{
					color: `#fef7e0`,
				},
			],
		},
		{
			featureType: `poi`,
			stylers: [
				{
					visibility: `off`,
				},
			],
		},
		{
			featureType: `road`,
			elementType: `geometry`,
			stylers: [
				{
					color: `#ffffff`,
				},
			],
		},
	],
};
