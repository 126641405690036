// ========= REACT =========
import React from "react";
import { connect } from "react-redux";

// ========= COMPONENTS =========
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import PopUp from "../PopUp";

// ========= UTILITIES =========
import { findFluidGatsbyImage } from "../../libs/utils";

// ========= TOKENS =========
import * as ACTIONS from "../../state/actions";
import COLORS from "@deeplocal/colors/google";
import SCENES from "../../etc/scenes";
import COPY from "../../etc/copy";

// ========= STYLED COMPONENTS =========
const GoButton = styled(Button)`
	&& {
		background: ${COLORS.defaults.yellow};

		border-radius: var(--radius);

		color: white;
		font-size: 1.5em;

		padding: 0 calc(var(--u) * 4);
		height: calc(var(--u) * 10);

		margin: calc(var(--u) * 4);

		filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);

		&:hover {
			background: ${COLORS.yellow[`600`]};
		}

		width: calc(var(--u) * 80);

		&:disabled {
			background: ${COLORS.grey[`400`]};
		}

		text-transform: unset;
	}
`;

const Icon = styled(Img)`
	position: absolute;
	margin-top: calc(var(--margin) * -1.5);
	width: calc(var(--u) * 75);
	/* border-radius: 0 0 50% 50% / 0 0 75% 75%; */
`;

const InsetP = styled.p`
	padding: 0 var(--margin);
`;

// ========= MAIN =========
const Finish = ({
	manager,
	onScoresButtonClick,
	onInitialsButtonClick,
	business,
	win,
	highscore,
}) => {
	return (
		<PopUp
			top={(() => {
				// conditional component returns
				switch (true) {
					// highscore win
					case win && highscore:
						return (
							<>
								<h3>
									{
										COPY[SCENES.FINISH].win
											.highScore.head
									}
								</h3>
								<h5>
									{
										COPY[SCENES.FINISH].win
											.highScore.subhead
									}
								</h5>
							</>
						);

					// lowscore win
					case win && !highscore:
						return (
							<>
								<h3>
									{
										COPY[SCENES.FINISH].win.lowScore
											.head
									}
								</h3>
								<h5>
									{
										COPY[SCENES.FINISH].win.lowScore
											.subhead
									}
								</h5>
							</>
						);

					// highscore lose
					case !win && highscore:
						return (
							<>
								<h3>
									{
										COPY[SCENES.FINISH].lose
											.highScore.head
									}
								</h3>
								<h5>
									{
										COPY[SCENES.FINISH].lose
											.highScore.subhead
									}
								</h5>
							</>
						);

					// lowscore lose
					case !win && !highscore:
						return (
							<>
								<h3>
									{
										COPY[SCENES.FINISH].lose
											.lowScore.head
									}
								</h3>
								<h5>
									{
										COPY[SCENES.FINISH].lose
											.lowScore.subhead
									}
								</h5>
							</>
						);

					default:
						return <></>;
				}
			})()}
			bottom={
				<>
					<Icon
						fluid={findFluidGatsbyImage(
							manager.images,
							win || highscore
								? business.winnerImg
								: business.loserImg
						)}
					/>

					{(() => {
						// conditional component returns
						switch (true) {
							// highscore win
							case win && highscore:
								return (
									<>
										<InsetP>
											{
												COPY[SCENES.FINISH].win
													.highScore.body
											}
										</InsetP>
										<GoButton
											onClick={
												onInitialsButtonClick
											}
										>
											{
												COPY[SCENES.FINISH].win
													.highScore.button
											}
										</GoButton>
									</>
								);

							// lowscore win
							case win && !highscore:
								return (
									<>
										<InsetP>
											{
												COPY[SCENES.FINISH].win
													.lowScore.body
											}
										</InsetP>
										<GoButton
											onClick={
												onScoresButtonClick
											}
										>
											{
												COPY[SCENES.FINISH].win
													.lowScore.button
											}
										</GoButton>
									</>
								);

							// highscore lose
							case !win && highscore:
								return (
									<>
										<InsetP>
											{
												COPY[SCENES.FINISH].lose
													.highScore.body
											}
										</InsetP>
										<GoButton
											onClick={
												onInitialsButtonClick
											}
										>
											{
												COPY[SCENES.FINISH].lose
													.highScore.button
											}
										</GoButton>
									</>
								);

							// lowscore lose
							case !win && !highscore:
								return (
									<>
										<InsetP>
											{
												COPY[SCENES.FINISH].lose
													.lowScore.body
											}
										</InsetP>
										<GoButton
											onClick={
												onScoresButtonClick
											}
										>
											{
												COPY[SCENES.FINISH].lose
													.lowScore.button
											}
										</GoButton>
									</>
								);

							default:
								return <></>;
						}
					})()}
				</>
			}
		/>
	);
};

export default connect(
	(state) => {
		return {
			business: state.business,
			player: state.player,
			cloud: state.cloud,
			win: state.win,
			highscore: state.highscore,
		};
	},
	(dispatch) => {
		return {
			onInitialsButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.INITIALS)),
			onScoresButtonClick: () =>
				dispatch(ACTIONS.setScene(SCENES.SCORE)),
		};
	}
)(Finish);
